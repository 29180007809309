// src/contexts/ToolsContext.tsx
import React, { createContext, useState, ReactNode, useContext } from 'react';

interface ToolsContextProps {
  selectedSubMenu: string | null;
  setSelectedSubMenu: (submenu: string | null) => void;
}

const ToolsContext = createContext<ToolsContextProps | undefined>(undefined);

export const ToolsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(null);

  return (
    <ToolsContext.Provider value={{ selectedSubMenu, setSelectedSubMenu }}>
      {children}
    </ToolsContext.Provider>
  );
};

// Custom hook for easier access
export const useTools = (): ToolsContextProps => {
  const context = useContext(ToolsContext);
  if (!context) {
    throw new Error('useTools must be used within a ToolsProvider');
  }
  return context;
};
