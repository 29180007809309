// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { AuthProvider } from "./contexts/AuthContext";
import { ToolsProvider } from './contexts/ToolsContext';
import { UserProvider } from './contexts/UserContext';

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ffc100", // 망고 색
    },
    secondary: {
      main: "#a3c2c2", // 기존 색상 유지
    },
    background: {
      default: "#fdf4e3", // 따뜻한 느낌의 배경
      paper: "#ffffff", // 콘텐츠 배경
    },
    text: {
      primary: "#333333", // 기본 텍스트는 유지
      secondary: "#666666", // 보조 텍스트 유지
    },
    divider: "#ffd966", // 약간의 노란 계열로 변경
  },
  typography: {
    fontFamily: "'Roboto', 'Noto Sans KR', sans-serif",
    h1: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "#ffffff", // Navbar 및 주요 텍스트 흰색
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 600,
      color: "#ffffff", // 추가 적용 필요 시
    },
    body1: {
      fontSize: "1rem",
      color: "#333333", // 본문 텍스트는 유지
    },
    body2: {
      fontSize: "0.875rem",
      color: "#666666", // 작은 본문 텍스트 유지
    },
  },
  shape: {
    borderRadius: 12, // 조금 더 둥글게
  },
});


ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
    <UserProvider>
      <ToolsProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </ToolsProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
