// src/pages/ContactPage.tsx
import React, { useState } from "react";
import { Box, Typography, TextField, Button, Link } from "@mui/material";
import Navbar from "../components/Navbar";
import axiosInstance from "../api/axios"; // Import the axios instance

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage(""); // Reset the response message

    try {
      // Use axiosInstance to send a POST request
      const response = await axiosInstance.post("/send-email", {
        ...formData,
        to: "aipixmon@gmail.com", // Hardcode the recipient email
      });

      // Assuming the server sends a success message in the response data
      if (response.status === 200) {
        setResponseMessage("Your message has been sent successfully!");
        // Optionally, reset the form
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        // Handle unexpected status codes
        setResponseMessage(`Failed to send message: ${response.data.error || "Unknown error."}`);
      }
    } catch (error: any) {
      // Handle errors from axios
      if (error.response) {
        // Server responded with a status other than 2xx
        setResponseMessage(`Failed to send message: ${error.response.data.error || "Server error."}`);
      } else if (error.request) {
        // Request was made but no response received
        setResponseMessage("No response from the server. Please try again later.");
      } else {
        // Something else happened while setting up the request
        setResponseMessage("An error occurred while sending your message.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ textAlign: "center", padding: "100px 20px" }}>
        <Typography variant="h2" sx={{ fontSize: "48px", fontWeight: "bold", color: "#333" }}>
          Contact Us
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "20px 0", color: "#555" }}>
          Have questions? Reach out to us!
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ maxWidth: "600px", margin: "0 auto", textAlign: "left" }}
        >
          <TextField
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            required
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            variant="outlined"
            type="email"
            value={formData.email}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            required
          />
          <TextField
            fullWidth
            label="Message"
            name="message"
            variant="outlined"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            required
          />
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
        {responseMessage && (
          <Typography
            sx={{
              color: responseMessage.includes("successfully") ? "green" : "red",
              marginTop: "20px",
            }}
          >
            {responseMessage}
          </Typography>
        )}
      </Box>

      {/* Customer Support Team */}
      <Box
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#f5f5f5",
          marginTop: "40px",
        }}
      >
        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", color: "#333" }}>
          Customer Support Team
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Email: <Link href="mailto:aipixmon@gmail.com">aipixmon@gmail.com</Link>
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Phone: 82-70-4060-7480
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Operating Hours: Mon-Fri 9:00 AM - 6:00 PM (Excluding holidays)
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Address: 16th Floor, HiteJinro Building, 14 SeochoJungang Street, Seocho-gu, Seoul, South Korea 06720
        </Typography>
      </Box>
      
      {/* Footer */}
      <Box
        className="footer"
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#333",
          color: "#fff",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          PIXMON
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
          <Link href="/about" color="inherit" underline="none">
            About Us
          </Link>
          <Link href="/contact" color="inherit" underline="none">
            Contact
          </Link>
          <Link href="/terms" color="inherit" underline="none">
            Terms of Service
          </Link>
          <Link href="/privacy" color="inherit" underline="none">
            Privacy Policy
          </Link>
          <Link href="/copyright" color="inherit" underline="none">
            Copyright
          </Link>
          <Link href="/support" color="inherit" underline="none">
            Support
          </Link>
        </Box>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          &copy; 2025 PIXMON. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactPage;
