// src/components/TabsContent/SettingsTab.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';

const SettingsTab: React.FC = () => {
  return (
    <Box>
      <Typography variant="h6">Settings</Typography>
      {/* 설정 관련 내용 추가 */}
    </Box>
  );
};

export default SettingsTab;
