// src/components/TabsContent/AutoTab.tsx
import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import { PromptContext } from '../../contexts/PromptContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import { SelectedImage } from '../../types';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share'; // ShareIcon 추가
import { Prompt } from '../../types/Prompt';

interface AutoTabProps {
  selectedImage: SelectedImage | null;
}

const AutoTab: React.FC<AutoTabProps> = ({ selectedImage }) => {
  const { prompts, setPrompts, selectedPrompt, setSelectedPrompt } = useContext(PromptContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const MAX_PROMPTS = 50; // Maximum number of prompts

  // Fetch prompts from the server
  const fetchPrompts = async (pageNumber: number) => {
    if (prompts.length >= MAX_PROMPTS) {
      setHasMore(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.get('/fetch-prompts', {
        params: { page: pageNumber, limit: 10 },
      });

      const newPrompts: Prompt[] = Array.isArray(response.data.prompts)
        ? response.data.prompts
        : [];

      // 필드가 sn, prompt, liked로 존재하는지 확인
      const validNewPrompts = newPrompts.filter(
        (p) => typeof p.sn === 'number' && typeof p.prompt === 'string' && typeof p.liked === 'boolean'
      );

      const availableSlots = MAX_PROMPTS - prompts.length;
      const promptsToAdd = validNewPrompts.slice(0, availableSlots);

      if (promptsToAdd.length < 10) {
        setHasMore(false);
      }

      // 기존 프롬프트와 새로운 프롬프트를 합친 후, liked 순으로 정렬
      const combinedPrompts = [...prompts, ...promptsToAdd];
      combinedPrompts.sort((a, b) => Number(b.liked) - Number(a.liked));

      setPrompts(combinedPrompts);
    } catch (error) {
      console.error('Failed to fetch prompts:', error);
      toast.error('Failed to fetch prompts.');
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (prompts.length >= MAX_PROMPTS) {
      setHasMore(false);
      return;
    }
    const nextPage = page + 1;
    setPage(nextPage);
    fetchPrompts(nextPage);
  };

  const handlePromptSelect = (promptSn: number) => {
    const prompt = prompts.find((p) => p.sn === promptSn);
    if (!prompt) return;

    if (selectedPrompt?.sn === promptSn) {
      setSelectedPrompt(null); // Deselect if already selected
    } else {
      setSelectedPrompt(prompt); // Select the new prompt
    }
  };

  const handleDelete = async (sn: number) => {
    try {
      const response = await axiosInstance.delete(`/delete-prompt/${sn}`);
      if (response.status === 200) {
        setPrompts((prev) => prev.filter((prompt) => prompt.sn !== sn));
        toast.success('The prompt has been removed.');
      } else {
        toast.error('Failed to delete prompt.');
      }
    } catch (error) {
      console.error('Failed to delete prompt:', error);
      toast.error('Failed to delete prompt.');
    }
  };
  
  const handleLikeToggle = async (sn: number, currentLiked: boolean) => {
    try {
      const response = await axiosInstance.put(`/update-prompt/${sn}`, {
        liked: !currentLiked,
      });
      if (response.status === 200) {
        // 상태 업데이트 후, 프롬프트를 다시 정렬
        setPrompts((prev) => {
          const updatedPrompts = prev.map((prompt) =>
            prompt.sn === sn ? { ...prompt, liked: !currentLiked } : prompt
          );
          updatedPrompts.sort((a, b) => Number(b.liked) - Number(a.liked));
          return updatedPrompts;
        });
        toast.success('The prompt status has been updated.');
      } else {
        toast.error('Failed to update prompt.');
      }
    } catch (error) {
      console.error('Failed to update prompt:', error);
      toast.error('Failed to update prompt.');
    }
  };
  
  // 새로운 공유 핸들러 추가
  const handleShare = async (sn: number) => {
    try {
      const response = await axiosInstance.post(`/share-prompt/${sn}`);
      if (response.status === 200) {
        toast.success('The prompt was shared successfully.');
      } else {
        toast.error('Failed to share prompt.');
      }
    } catch (error) {
      console.error('Failed to share prompt:', error);
      toast.error('Failed to share prompt.');
    }
  };

  // Reset selectedPrompt when no image is selected
  useEffect(() => {
    if (!selectedImage) {
      setSelectedPrompt(null);
    }
  }, [selectedImage, setSelectedPrompt]);

  // Render when no image is loaded
  if (!selectedImage) {
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Recommended Prompts
        </Typography>
        <Typography variant="body2" gutterBottom>
          Load an image to see recommended prompts.
        </Typography>

        <Stack spacing={2} alignItems="flex-start">
          {Array.from({ length: 10 }).map((_, index) => (
            <Paper key={index} variant="outlined" sx={{ padding: 2, width: '100%' }}>
              <Button variant="outlined" disabled>
                {`Prompt ${index + 1}`}
              </Button>
            </Paper>
          ))}
        </Stack>
      </Box>
    );
  }

  // Render when image is loaded
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Recommended Prompts
      </Typography>
      <Typography variant="body2" gutterBottom>
        Select a prompt to generate images.
      </Typography>

      <Stack spacing={2} alignItems="flex-start">
        {prompts.map((prompt) => (
          <Paper
            key={prompt.sn}
            variant="outlined"
            sx={{ width: '100%', position: 'relative', padding: 1 }}
            onMouseEnter={(e) => {
              const overlay = e.currentTarget.querySelector('.overlay');
              if (overlay) overlay.setAttribute('style', 'opacity: 1');
            }}
            onMouseLeave={(e) => {
              const overlay = e.currentTarget.querySelector('.overlay');
              if (overlay) overlay.setAttribute('style', 'opacity: 0');
            }}
          >
            <Button
              variant={selectedPrompt?.sn === prompt.sn ? 'contained' : 'outlined'}
              color={selectedPrompt?.sn === prompt.sn ? 'primary' : 'inherit'}
              fullWidth
              onClick={() => handlePromptSelect(prompt.sn)}
              sx={{ textTransform: 'none', paddingRight: '60px' }} // 아이콘 공간 확보를 위해 패딩 조정
            >
              {prompt.prompt}
            </Button>
            {/* Overlay for icons */}
            <Box
              className="overlay"
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                opacity: 0,
                transition: 'opacity 0.3s',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // 어두운 배경
                borderRadius: '4px',
                padding: 1,
              }}
            >
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(prompt.sn)}
                  sx={{ color: 'white' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={prompt.liked ? 'Unlike' : 'Like'}>
                <IconButton
                  aria-label="like"
                  onClick={() => handleLikeToggle(prompt.sn, prompt.liked)}
                  sx={{ color: 'white' }}
                >
                  {prompt.liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
              </Tooltip>
              {/* Share Tooltip 주석 위치 조정 */}
              <Tooltip title="Share">
                <IconButton
                  aria-label="share"
                  onClick={() => handleShare(prompt.sn)}
                  sx={{ color: 'white' }}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        ))}
      </Stack>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {!loading && hasMore && prompts.length < MAX_PROMPTS && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleLoadMore}
            disabled={!hasMore || prompts.length >= MAX_PROMPTS}
          >
            Load More
          </Button>
        </Box>
      )}

      {!hasMore && (
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          No more prompts available.
        </Typography>
      )}
    </Box>
  );
};

export default AutoTab;
