// src/components/ToolsTab.tsx
import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Grid,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ListIcon from '@mui/icons-material/List';
import AppsIcon from '@mui/icons-material/Apps';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useTools } from '../contexts/ToolsContext'; // Import the custom hook

// 카테고리 및 서브메뉴 데이터
interface Category {
  name: string;
  subMenu: string[];
}

// 10개의 카테고리 및 서브 메뉴 데이터
const categories: Category[] = [
  { name: 'Generation', subMenu: ['SDXL-Lightning', 'FLUX.1-schnell'] },
  { name: 'Background', subMenu: ['PixMon-bg-remove', 'PixMon-SDXL-bg-change'] },
  { name: 'Text', subMenu: ['Text-overlay', 'Text-canny-controlnet'] },
  { name: 'Edit', subMenu: ['AI Images', 'Adnimated'] },
  { name: 'Resize', subMenu: ['AI outpainting', 'AI image enhancer'] },
  { name: 'Video', subMenu: ['Video Editing', 'Video Effects'] },
  { name: 'Profile', subMenu: ['Virtual Model Generator', 'Video Generator'] },
  { name: '3D', subMenu: ['3D Models', '3D Text'] },
  { name: 'Style', subMenu: ['PixMon-leffa','PixMon-SDXL-ipadapter', 'SDXL-lora'] },
  { name: 'Misc', subMenu: ['3d-figure', 'Visual-identity-design'] },
];

const ToolsTab: React.FC = () => {
  const { setSelectedSubMenu } = useTools(); // Access the context
  const [expanded, setExpanded] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<'tree' | 'list' | 'icon'>('tree');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleViewSwitch = (mode: 'tree' | 'list' | 'icon') => {
    setViewMode(mode);
    if (mode === 'icon') {
      // 아이콘 뷰로 전환할 때 기본적으로 첫 번째 카테고리 선택
      setSelectedCategory(categories[0]?.name || null);
    } else {
      // 다른 뷰로 전환할 때 선택된 카테고리 초기화
      setSelectedCategory(null);
    }
  };

  // Handler for submenu selection
  const handleSubMenuSelect = (submenu: string) => {
    setSelectedSubMenu(submenu);
  };

  return (
    <Box p={2} sx={{ height: '100vh', overflowY: 'auto' }}>
      <Typography variant="h5" mb={2} fontWeight="bold" /* color="primary" */ sx={{ color: '#e6a900' }}>
        Tools
      </Typography>

      {/* 뷰 모드 전환 버튼 */}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant={viewMode === 'tree' ? 'contained' : 'outlined'}
          startIcon={<ListIcon />}
          onClick={() => handleViewSwitch('tree')}
          sx={{ mr: 1 }}
        >
          Tree
        </Button>
        <Button
          variant={viewMode === 'list' ? 'contained' : 'outlined'}
          startIcon={<ListIcon />}
          onClick={() => handleViewSwitch('list')}
          sx={{ mr: 1 }}
        >
          List
        </Button>
        <Button
          variant={viewMode === 'icon' ? 'contained' : 'outlined'}
          startIcon={<AppsIcon />}
          onClick={() => handleViewSwitch('icon')}
        >
          Icon
        </Button>
      </Box>

      {/* 카테고리와 서브 메뉴 */}
      {viewMode === 'tree' ? (
        <TreeView
          expanded={expanded}
          onNodeToggle={handleToggle}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
            padding: '0px',
            // TreeView 전체 인덴트 줄이기
            '& .MuiTreeItem-root > .MuiTreeItem-content': {
              paddingLeft: '0px', // 기본 인덴트보다 작게 설정
            },
            '& .MuiTreeItem-group': {
              marginLeft: '0px', // 그룹 마진 제거
            },
            '& .MuiTreeItem-label': {
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '0px', // 라벨 자체의 패딩을 줄임
            },
          }}
        >
          {categories.map((category, index) => (
            <TreeItem
              key={`category-${index}`}
              nodeId={`category-${index}`}
              label={
                <Box display="flex" alignItems="center">
                  <FolderIcon sx={{ color: '#FFC107', mr: 1 }} />
                  <Typography fontWeight="bold">{category.name}</Typography>
                </Box>
              }
              sx={{
                '& .MuiTreeItem-label': {
                  paddingLeft: '0px', // 서브메뉴 인덴트 조정
                },
              }}
            >
              {category.subMenu.map((item, subIndex) => (
                <TreeItem
                  key={`subMenu-${index}-${subIndex}`}
                  nodeId={`subMenu-${index}-${subIndex}`}
                  label={
                    <Box display="flex" alignItems="center" pl={2}>
                      <InsertDriveFileIcon sx={{ color: '#4CAF50', mr: 1 }} />
                      <Typography
                        variant="body2"
                        onClick={() => handleSubMenuSelect(item)} // Handle click
                        sx={{ cursor: 'pointer' }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  }
                  sx={{
                    '& .MuiTreeItem-label': {
                      paddingLeft: '0px', // 서브메뉴 인덴트 조정
                    },
                  }}
                />
              ))}
            </TreeItem>
          ))}
        </TreeView>
      ) : viewMode === 'list' ? (
        categories.map((category) => (
          <Box key={category.name} mb={2}>
            <Typography variant="h6" fontWeight="bold" color="secondary">
              {category.name}
            </Typography>
            <Box>
              {category.subMenu.map((item) => (
                <Typography
                  key={item}
                  variant="body2"
                  sx={{ pl: 2, cursor: 'pointer' }}
                  onClick={() => handleSubMenuSelect(item)} // Handle click
                >
                  • {item}
                </Typography>
              ))}
            </Box>
          </Box>
        ))
      ) : (
        // 아이콘 뷰
        <>
          {/* 카테고리 선택 버튼 */}
          <Box display="flex" flexWrap="wrap" mb={2}>
            {categories.map((category) => (
              <Button
                key={category.name}
                variant={selectedCategory === category.name ? 'contained' : 'outlined'}
                onClick={() => setSelectedCategory(category.name)}
                sx={{ mr: 1, mb: 1 }}
              >
                {category.name}
              </Button>
            ))}
          </Box>

          {/* 선택된 카테고리의 아이콘만 표시 */}
          <Grid container spacing={2}>
            {selectedCategory &&
              categories
                .find((cat) => cat.name === selectedCategory)
                ?.subMenu.map((item) => (
                  <Grid item xs={6} key={`${selectedCategory}-${item}`}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      p={2}
                      sx={{
                        border: '1px solid #ddd',
                        borderRadius: 1,
                        textAlign: 'center',
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                      }}
                      onClick={() => handleSubMenuSelect(item)} // Handle click
                    >
                      <InsertDriveFileIcon color="primary" fontSize="large" />
                      <Typography variant="caption" fontWeight="bold" mt={1}>
                        {item}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ToolsTab;
