// src/pages/TermsPage.tsx
import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Navbar from "../components/Navbar";

const TermsPage: React.FC = () => {
  return (
    <Box>
      <Navbar />
      <Box sx={{ textAlign: "left", padding: "100px 20px", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h2" sx={{ fontSize: "48px", fontWeight: "bold", color: "#333" }}>
          Terms of Service
        </Typography>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          1. Service Usage Agreement
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          1.1 Formation of Agreement
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          This service usage agreement is established when a member agrees to the terms of service, applies for
          service use according to the company's procedures, and the company accepts this application.
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          1.2 Application for Use
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Service use application is made by filling in the necessary information according to the online
          registration form set by the company. Members must provide their actual information.
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          1.3 Acceptance and Limitation of Application
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          The company will, in principle, accept service use applications in order of receipt when there are no
          operational or technical obstacles.
        </Typography>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          2. Service Usage
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          2.1 Service Hours
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          The service is provided 24 hours a day, 365 days a year. However, the company may change part or all of
          the provided services according to the company's needs.
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          2.2 Service Usage Restrictions
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Members must comply with relevant laws, provisions of these terms, usage guidelines, and precautions
          announced in relation to the service. Members must not engage in activities that interfere with company
          operations or damage the company's reputation.
        </Typography>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          3. Obligations and Responsibilities
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          3.1 Company Obligations
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          The company strives to provide stable service. The company builds security systems and publishes and
          complies with privacy policies to protect members' personal information.
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          3.2 Member Obligations
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Members must provide accurate personal information necessary for service use. Members are responsible
          for managing their account and password.
        </Typography>
      </Box>

      {/* Footer */}
      <Box
        className="footer"
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#333",
          color: "#fff",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          PIXMON
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
          <Link href="/about" color="inherit" underline="none">
            About Us
          </Link>
          <Link href="/contact" color="inherit" underline="none">
            Contact
          </Link>
          <Link href="/terms" color="inherit" underline="none">
            Terms of Service
          </Link>
          <Link href="/privacy" color="inherit" underline="none">
            Privacy Policy
          </Link>
          <Link href="/copyright" color="inherit" underline="none">
            Copyright
          </Link>
          <Link href="/support" color="inherit" underline="none">
            Support
          </Link>
        </Box>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          &copy; 2025 PIXMON. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsPage;
