// src/components/ImageGenerator.tsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  styled,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
  Fade,
  Checkbox,
  IconButton,
  ImageList,
  ImageListItem,
  Card,
  CardMedia,
  Modal,
  Divider,
  TextField,
  Collapse,
  Tooltip,
  Backdrop,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useDropzone, FileRejection } from 'react-dropzone';
import SliderBar from './SliderBar';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import { SelectedImage } from '../types';
import { saveAs } from 'file-saver';
import axiosInstance from '../api/axios';
import { useTools } from '../contexts/ToolsContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useUser } from '../contexts/UserContext';

// 타입 정의
interface ImageData {
  id: string;
  originalFile: File;
  originalUrl: string;
  processedUrl?: string;
  error?: string;
  isProcessing: boolean;
  isSelected?: boolean;
}

interface ImageGeneratorProps {
  selectedImage: SelectedImage | null;
  setSelectedImage: (image: SelectedImage | null) => void;
  describeText: string;
  dontWantText: string;
  selectedCustomImage: string | null;
  positivePrompt: string;
  negativePrompt: string;
  selectedTab: string;
  fileList?: BgFile[];
}

interface BgFile {
  file_hash: string;
  uuid_folder: string;
  filename: string;
  url: string;
}

interface ComposedImageData {
  id: string;
  url: string;
  originalUrl: string;
  isSelected: boolean;
  showOverlay?: boolean;
  isLiked?: boolean; // Added property for like status
}

// 스타일 정의
const ViewerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '600px',
  aspectRatio: '1 / 1',
  backgroundColor: '#fafafa',
  backgroundImage: `linear-gradient(45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                    linear-gradient(-45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, ${theme.palette.grey[300]} 75%),
                    linear-gradient(-45deg, transparent 75%, ${theme.palette.grey[300]} 75%)`,
  backgroundSize: '20px 20px',
  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
  border: '2px dashed #FFEB3B',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[6],
  overflow: 'auto',
  margin: '0 auto',
  cursor: 'pointer',
}));

const StyledImage = styled('img')<{ scale: number }>(({ scale }) => ({
  width: `${scale}%`,
  height: `${scale}%`,
  objectFit: scale > 100 ? 'contain' : 'cover',
  transition: 'opacity 0.3s',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 3,
}));

const EditingResultsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const ButtonGroupContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(1),
}));

const FullscreenOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  overflowY: 'auto',
}));

const OverlayContent = styled(Box)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  position: 'relative',
  width: isMobile ? '90%' : 'calc(100% - 200px)',
  height: isMobile ? '90%' : '80%',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    height: '90%',
    flexDirection: 'column',
  },
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  [theme.breakpoints.down('sm')]: {
    top: '50%',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

const ImageGenerator: React.FC<ImageGeneratorProps> = ({
  selectedImage,
  setSelectedImage,
  describeText,
  dontWantText,
  selectedCustomImage,
  positivePrompt,
  negativePrompt,
  selectedTab,
  fileList,
}) => {
  const { selectedSubMenu } = useTools(); // Access the context
  const { userProfile, loading: userLoading, error: userError, updateCredit } = useUser(); // **2. Use useUser**
  const [images, setImages] = useState<ImageData[]>([]);
  const [secondImages, setSecondImages] = useState<ImageData[]>([]); // New state
  const [quality, setQuality] = useState<string>('High Quality & Low Speed');
  const [scale, setScale] = useState<number>(100);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string>('');
  const [composeProcessedImages, setComposeProcessedImages] = useState<ComposedImageData[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [positivePromptInput, setPositivePromptInput] = useState<string>(positivePrompt);
  const [negativePromptInput, setNegativePromptInput] = useState<string>(negativePrompt);

  // State for collapsing the title and description
  const [isTitleExpanded, setIsTitleExpanded] = useState<boolean>(false);

  // Dropzone 키 관리
  const [mainDropzoneKey, setMainDropzoneKey] = useState<string>(uuidv4());
  const [secondDropzoneKey, setSecondDropzoneKey] = useState<string>(uuidv4());

  const itemSize = isMobile ? 150 : 200;

  // 전체 오버레이 관리
  const [fullscreenOverlay, setFullscreenOverlay] = useState<{
    open: boolean;
    currentImageIndex: number;
  }>({
    open: false,
    currentImageIndex: 0,
  });

  // Infinite Scroll 상태 관리
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [skip, setSkip] = useState<number>(0);
  const limit = 10; // 한 번에 로드할 이미지 수

  // Configuration Object for Submenus
  const submenuConfig: { [key: string]: SubmenuConfig } = {
    'SDXL-Lightning': {
      title: 'SDXL Lightning',
      description: 'Generate high-quality images with lightning-fast processing using SDXL Lightning.',
      requiresMainImage: false,
      requiresSecondImage: false,
      requiresPositivePrompt: true,
      requiresNegativePrompt: true,
      apiUrl: '/pixmon-sdxl-lightning',
      availableOptions: ['width', 'height'],
    },
    'FLUX.1-schnell': {
      title: 'FLUX 1-Schnell',
      description: 'Utilize FLUX 1-Schnell for rapid image generation with dual image support.',
      requiresMainImage: true,
      requiresSecondImage: true,
      requiresPositivePrompt: true,
      requiresNegativePrompt: true,
      apiUrl: '/pixmon-flux-schnell',
    },
    'PixMon-bg-remove': {
      title: 'PixMon Background Remove',
      description: 'Easily remove backgrounds from your images with PixMon Background Remove.',
      requiresMainImage: true,
      requiresSecondImage: false,
      requiresPositivePrompt: false,
      requiresNegativePrompt: false,
      apiUrl: '/pixmon-bg-remove',
    },
    'PixMon-SDXL-bg-change': {
      title: 'PixMon SDXL Background Change',
      description: 'Change backgrounds seamlessly using PixMon SDXL Background Change.',
      requiresMainImage: true,
      requiresSecondImage: false,
      requiresPositivePrompt: true,
      requiresNegativePrompt: false,
      apiUrl: '/pixmon-bg-change-1',
    },
    'Text-canny-controlnet': {
      title: 'Text Canny ControlNet',
      description: 'Enhance your images with text-based Canny ControlNet integrations.',
      requiresMainImage: true,
      requiresSecondImage: false,
      requiresPositivePrompt: true,
      requiresNegativePrompt: true,
      apiUrl: '/pixmon-text-1',
    },
    'AI outpainting': {
      title: 'AI Outpainting',
      description: 'Expand your images beyond their original boundaries with AI Outpainting.',
      requiresMainImage: true,
      requiresSecondImage: false,
      requiresPositivePrompt: true,
      requiresNegativePrompt: false,
      apiUrl: '/pixmon-outpaint',
      availableOptions: ['x_percent', 'y_percent'],
    },
    'PixMon-leffa': {
      title: 'PixMon Leffa',
      description: 'Create stunning visuals with PixMon Leffa, supporting dual image uploads.',
      requiresMainImage: true,
      requiresSecondImage: true,
      requiresPositivePrompt: false,
      requiresNegativePrompt: false,
      apiUrl: '/pixmon-leffa-create',
    },

    // Add more submenus as needed with title and description
  };

  interface SubmenuConfig {
    title: string;
    description: string;
    requiresMainImage: boolean;
    requiresSecondImage: boolean;
    requiresPositivePrompt: boolean;
    requiresNegativePrompt: boolean;
    apiUrl: string;
    availableOptions?: string[]; // Optional property to define available option fields
  }

  // onDrop 핸들러 정의 (First Upload)
  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // onDropMain 핸들러 내 Dropzone 키 업데이트
      setMainDropzoneKey(uuidv4());

      const imageFiles = acceptedFiles.filter((file) => file.type.startsWith('image/'));
      
      try {
        // 이미지를 자르고 설정하는 비동기 처리
        const newImages: ImageData[] = await Promise.all(
          imageFiles.map(async (file) => {
            const croppedFile = await cropAndResizeImageToSquare(file);
            return {
              id: uuidv4(),
              originalFile: croppedFile,
              originalUrl: URL.createObjectURL(croppedFile),
              isProcessing: false,
              isSelected: false,
              showOverlay: false,
            };
          })
        );

        // 새 이미지를 설정
        setImages(newImages);
        // setImages((prevImages) => [...prevImages, ...newImages]);

        // 선택된 이미지를 설정 (옵션, 필요에 따라 활성화)
        // 새로운 업로드가 발생한 경우 첫 번째 이미지를 선택
        if (newImages.length > 0) {
          const firstNewImage = newImages[0];
          setSelectedImage({
            id: firstNewImage.id,
            url: firstNewImage.originalUrl,
            source: 'upload',
            originalUrl: firstNewImage.originalUrl,
          });
        }
        // Handle rejected files (if any)
        fileRejections.forEach(({ file, errors }) => {
          errors.forEach((e) => {
            toast.error(`파일 업로드 실패: ${file.name} - ${e.message}`);
          });
        });
      } catch (err) {
        console.error('이미지 업로드 및 처리 중 오류:', err);
        toast.error('이미지 업로드 및 처리 중 오류가 발생했습니다.');
      }
    },
    [selectedImage, setSelectedImage]
  );

  // onDrop 핸들러 정의 (Second Upload)
  const onSecondDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // onDropSecond 핸들러 내 Dropzone 키 업데이트
      setSecondDropzoneKey(uuidv4());
      const imageFiles = acceptedFiles.filter((file) => file.type.startsWith('image/'));
      
      try {
        const newSecondImages: ImageData[] = await Promise.all(
          imageFiles.map(async (file) => {
            const croppedFile = await cropAndResizeImageToSquare(file);
            return {
              id: uuidv4(),
              originalFile: croppedFile,
              originalUrl: URL.createObjectURL(croppedFile),
              isProcessing: false,
              isSelected: false,
              showOverlay: false,
            };
          })
        );

        setSecondImages(newSecondImages);
        // 두 번째 이미지를 설정
        // setSecondImages((prevImages) => [...prevImages, ...newSecondImages]);

        // Handle rejected files
        fileRejections.forEach(({ file, errors }) => {
          errors.forEach((e) => {
            toast.error(`파일 업로드 실패: ${file.name} - ${e.message}`);
          });
        });
      } catch (err) {
        console.error('두 번째 이미지 업로드 및 처리 중 오류:', err);
        toast.error('두 번째 이미지 업로드 및 처리 중 오류가 발생했습니다.');
      }
    },
    []
  );

  // Slider Change Handler
  const handleScaleChange = (newScale: number) => {
    setScale(newScale);
  };

  // Image List Click Handler
  const handleImageListClick = (image: ComposedImageData) => {
    openFullscreenOverlay(image.id); // 오버레이 열기만 수행
  };

  // Configure the first dropzone
  const { getRootProps, getInputProps, isDragActive: isDragActiveMain, open: openDropzoneMain } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

  // Configure the second dropzone
  const {
    getRootProps: getSecondRootProps,
    getInputProps: getSecondInputProps,
    isDragActive: isDragActiveSecond,
    open: openSecondDropzone,
  } = useDropzone({
    onDrop: onSecondDrop,
    accept: { 'image/*': [] },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

  // fileList가 변경될 때 composeProcessedImages 업데이트
  useEffect(() => {
    if (fileList && fileList.length > 0) {
      const mappedImages: ComposedImageData[] = fileList.map((file) => ({
        id: uuidv4(),
        url: file.url,
        originalUrl: file.url,
        isSelected: false,
        showOverlay: false,
        isLiked: false, // Initialize like status
      }));
      setComposeProcessedImages(mappedImages);
    }
  }, [fileList]);

  useEffect(() => {
    // 컴포넌트 언마운트 시 URL 해제
    return () => {
      images.forEach((image) => {
        URL.revokeObjectURL(image.originalUrl);
        if (image.processedUrl) {
          URL.revokeObjectURL(image.processedUrl);
        }
      });
      secondImages.forEach((image) => {
        URL.revokeObjectURL(image.originalUrl);
        if (image.processedUrl) {
          URL.revokeObjectURL(image.processedUrl);
        }
      });
    };
  }, [images, secondImages]);

    // 추가: selectedSubMenu가 변경될 때 ImageGenerator의 상태 초기화
    useEffect(() => {
      // 선택된 서브메뉴가 변경될 때 상태를 초기화
      setImages([]);
      setSecondImages([]);
      setComposeProcessedImages([]);
      setSelectedImage(null); // 선택된 이미지도 초기화
      setPositivePromptInput('');
      setNegativePromptInput('');
      setOptionsState({
        width: 1024,
        height: 1024,
        x_percent: 75,
        y_percent: 76,
      });
      setShowAdvancedOptions(false);
    }, [selectedSubMenu]);
  

  const deleteImageOnServer = async (imageUrl: string) => {
    try {
      await axiosInstance.post(
        '/delete_gi_file',
        { url: imageUrl },
        { withCredentials: true }
      );
      console.log(`Successfully deleted image on server: ${imageUrl}`);
    } catch (error) {
      console.error(`Failed to delete image on server: ${imageUrl}`, error);
      throw error;
    }
  };

  // ViewerBox에 표시할 이미지 URL 결정 함수
  const getViewerBoxImageUrl = useCallback(() => {
    if (selectedImage) {
      const imageData = images.find((img) => img.id === selectedImage.id);
      return imageData?.processedUrl || imageData?.originalUrl || null;
    }
    if (images.length > 0) {
      return images[0].processedUrl || images[0].originalUrl || null;
    }
    return null;
  }, [selectedImage, images]);

  useEffect(() => {
    // 탭 전환 후에도 이미지 상태가 유지되도록 하기 위해 selectedImage 복원
    if (selectedImage && !images.find((img) => img.id === selectedImage.id)) {
      const restoredImage = images[0];
      if (restoredImage) {
        setSelectedImage({
          id: restoredImage.id,
          url: restoredImage.originalUrl,
          source: 'upload',
          originalUrl: restoredImage.originalUrl,
        });
      }
    }
  }, [selectedImage, images, setSelectedImage]);
  
  useEffect(() => {
    // 탭 이동 후 상태를 초기화하지 않도록 설정
    if (images.length > 0 && !selectedImage) {
      const firstImage = images[0];
      setSelectedImage({
        id: firstImage.id,
        url: firstImage.originalUrl,
        source: 'upload',
        originalUrl: firstImage.originalUrl,
      });
    }
  }, [images, selectedImage, setSelectedImage]);

  // 이미지 정사각형으로 자르고 512x512로 리사이즈하는 함수
  const cropAndResizeImageToSquare = async (file: File): Promise<File> => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const sideLength = Math.min(image.width, image.height);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (ctx) {
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = sideLength;
      tempCanvas.height = sideLength;
      const tempCtx = tempCanvas.getContext('2d');

      if (tempCtx) {
        const xOffset = (image.width - sideLength) / 2;
        const yOffset = (image.height - sideLength) / 2;
        tempCtx.drawImage(image, xOffset, yOffset, sideLength, sideLength, 0, 0, sideLength, sideLength);
        
        // 512x512 크기로 리사이즈
        canvas.width = 512;
        canvas.height = 512;
        ctx.drawImage(tempCanvas, 0, 0, sideLength, sideLength, 0, 0, 512, 512);
      }
    }

    const resizedBlob = await new Promise<Blob | null>((resolve) =>
      canvas.toBlob((blob) => resolve(blob), 'image/png')
    );

    if (resizedBlob) {
      return new File([resizedBlob], file.name, { type: 'image/png' });
    } else {
      throw new Error('Image resizing failed');
    }
  };


  // 클라이언트의 Infinite Scroll을 위한 이미지 페칭 함수
  const fetchImages = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/images', {
        params: { skip, limit },
        withCredentials: true,
      });
      const fetchedImages: string[] = response.data.images; // 서버에서 반환된 이미지 URL 배열

      if (fetchedImages.length < limit) {
        setHasMore(false); // 더 이상 로드할 이미지가 없음
      }

      // 서버에서 가져온 이미지를 composeProcessedImages에 추가
      const newFetchedImages: ComposedImageData[] = fetchedImages.map((url) => ({
        id: uuidv4(),
        url: url,
        originalUrl: url,
        isSelected: false,
        showOverlay: false,
        isLiked: false, // Initialize like status
      }));

      setComposeProcessedImages((prevImages) => [
        ...prevImages,
        ...newFetchedImages, // 기존 이미지 뒤에 추가
      ]);

      setSkip((prevSkip) => prevSkip + limit);
    } catch (error) {
      console.error('이미지 페칭 오류:', error);
      toast.error('이미지를 로드하는 데 실패했습니다.');
      setHasMore(false);
    }
  }, [skip, limit]);

  useEffect(() => {
    // 초기 이미지 로드
    fetchImages();
  }, []); // 빈 의존성 배열로 컴포넌트 마운트 시 한 번 실행


  // 쿠키 관리 헬퍼 함수
  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  };

  const setCookie = (name: string, value: string, days: number = 1) => {
    const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;

    if (!serviceDomain) {
      console.error('환경변수 REACT_APP_SERVICE_DOMAIN이 설정되지 않았습니다.');
      return;
    }
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};Domain=${serviceDomain}; path=/;Secure;SameSite=Strict`;
  };
  
  // 배경 제거 요청 핸들러
  const handleRemoveBackground = async (id: string, file: File) => {
    const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;

    if (!serviceDomain) {
      console.error('환경변수 REACT_APP_SERVICE_DOMAIN이 설정되지 않았습니다.');
      return;
    }
    const computeSHA256 = async (file: File): Promise<string> => {
      const arrayBuffer = await file.arrayBuffer();
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    };

    try {
      const fileHash = await computeSHA256(file);
      console.log(`File Hash: ${fileHash}`);

      document.cookie = `file_hash=${fileHash}; Domain=${serviceDomain}; path=/; Secure;SameSite=None`;

      const formData = new FormData();
      formData.append('file', file);

      const response = await axiosInstance.post(
        '/remove-background',
        formData,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      const processedImageBlob = new Blob([response.data], { type: 'image/png' });
      const processedImageURL = URL.createObjectURL(processedImageBlob);

      setImages((prevImages) =>
        prevImages.map((img) =>
          img.id === id
            ? { ...img, processedUrl: processedImageURL, isProcessing: false }
            : img
        )
      );

      setSecondImages((prevSecondImages) =>
        prevSecondImages.map((img) =>
          img.id === id
            ? { ...img, processedUrl: processedImageURL, isProcessing: false }
            : img
        )
      );
    } catch (err: any) {
      console.error('배경 제거 에러:', err);
      toast.error('이미지 처리를 실패했습니다. 원본 이미지를 표시합니다.');
      setImages((prevImages) =>
        prevImages.map((img) =>
          img.id === id ? { ...img, error: 'Background removal failed', isProcessing: false } : img
        )
      );
      setSecondImages((prevSecondImages) =>
        prevSecondImages.map((img) =>
          img.id === id ? { ...img, error: 'Background removal failed', isProcessing: false } : img
        )
      );
    }
  };

  // Compose Background Handler
  const handleGenerateButton = async () => {
      // **3.1. 사용자 크레딧 확인**
      if (!userProfile || userProfile.credit <= 0) {
        toast.error('크레딧이 부족합니다. 더 많은 크레딧을 구매해주세요.');
        return;
      }

    // 선택된 서브메뉴 접근
    const submenu = selectedSubMenu;

    // 서브메뉴가 선택되지 않은 경우 경고 메시지 표시
    if (!submenu) {
      toast.warning('이미지를 생성하기 전에 서브메뉴를 선택해주세요.');
      return;
    }

    // 선택된 서브메뉴의 구성 가져오기
    const config = submenuConfig[submenu];

    if (!config) {
      toast.error('지원되지 않는 서브메뉴가 선택되었습니다.');
      return;
    }

    // 필수 프롬프트 검증
    if (config.requiresPositivePrompt && positivePromptInput.trim() === '') {
      toast.warning('긍정적인 프롬프트를 입력해주세요.');
      return;
    }

    if (config.requiresNegativePrompt && negativePromptInput.trim() === '') {
      toast.warning('부정적인 프롬프트를 입력해주세요.');
      return;
    }

    // 필수 이미지 검증
    if (config.requiresMainImage) {
      if (images.length === 0) {
        toast.error('메인 이미지를 업로드해주세요.');
        return;
      }
      const mainImageData = images[0]; // 첫 번째 이미지를 메인 이미지로 사용
      if (!mainImageData.originalFile) {
        toast.warning('메인 이미지가 여전히 업로드 중입니다.');
        return;
      }
    }

    if (config.requiresSecondImage) {
      if (secondImages.length === 0) {
        toast.warning('배경으로 사용할 두 번째 이미지를 업로드해주세요.');
        return;
      }
      const backgroundImage = secondImages[0];
      if (!backgroundImage.originalFile) {
        toast.warning('배경 이미지가 여전히 업로드 중입니다.');
        return;
      }
    }

    // API 요청을 위한 FormData 구성
    let apiUrl = config.apiUrl;
    let formData = new FormData();

    // 품질 옵션 추가
    formData.append('quality_option', quality);

    // 필요에 따라 프롬프트 추가
    if (config.requiresPositivePrompt) {
      formData.append('positive_prompt', positivePromptInput);
    }

    if (config.requiresNegativePrompt) {
      formData.append('negative_prompt', negativePromptInput);
    }

    // Add the advanced options if they are available and the toggle is on
    if (displayConfig.showAdvancedOptions && displayConfig.availableOptions) {
      displayConfig.availableOptions.forEach((option) => {
        const value = options[option as keyof typeof options];
        if (value !== undefined) {
          formData.append(option, value.toString());
        }
      });
    }

    // 메인 이미지 추가
    if (config.requiresMainImage && images.length > 0) {
      // const mainImageData = images[0];
      const mainImageData = images.find(img => img.id === selectedImage?.id)
                    ?? images[images.length - 1]; // fallback

      console.log('Appending main_file:', mainImageData.originalFile);
      formData.append('main_file', mainImageData.originalFile);
    }

    // 배경 이미지 추가
    if (config.requiresSecondImage && secondImages.length > 0) {
      const backgroundImage = secondImages[0];
      if (backgroundImage.originalFile) {
        console.log('Appending sub_file:', backgroundImage.originalFile);
        formData.append('sub_file', backgroundImage.originalFile); // 'sub_file' 키로 추가
      } else {
        toast.error('배경 이미지 파일이 없습니다.');
        return;
      }
    }


    // 서버 요청 시작
    setIsLoading(true); // Set loading state to true
    setError('');

    const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;
    if (!serviceDomain) {
      console.error('환경변수 REACT_APP_SERVICE_DOMAIN이 설정되지 않았습니다.');
      return;
    }
    const client_id = uuidv4()
    document.cookie = `client_id=${client_id}; Domain=${serviceDomain}; path=/; Secure;SameSite=Strict`;

    try {
      const response = await axiosInstance.post(apiUrl, formData, { responseType: 'blob', withCredentials: true });

      const contentType = response.headers['content-type'];
      if (contentType.startsWith('image/')) {
        const processedImageBlob = new Blob([response.data], { type: 'image/png' });
        const processedImageURL = URL.createObjectURL(processedImageBlob);

        // Get current date for the path
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const datePath = `${year}/${month}/${day}`;

        // Sanitize email (assuming userProfile.email exists)
        const sanitizeEmail = (email: string) => {
          return email?.replace(/[@.]/g, '_') || 'unknown_user';
        };
        const sanitizedEmail = sanitizeEmail(userProfile?.email || '');

        const newComposedImage: ComposedImageData = {
          id: client_id,
          url: processedImageURL,
          originalUrl: config.requiresMainImage ? images[0].originalUrl : '',
          isSelected: false,
          showOverlay: false,
          isLiked: false, // Initialize like status
        };

        // setComposeProcessedImages((prevImages) => [
        //   newComposedImage,
        //   ...prevImages,
        // ]);        

        // 새 이미지가 맨 앞에 추가되도록 수정
        setComposeProcessedImages((prevImages) => {
          const updatedImages = [newComposedImage, ...prevImages].map(img => {
            if (img.url.startsWith('blob:')) {
              // Construct the new URL format
              const newUrl = `${process.env.REACT_APP_API_BASE_URL}/processed/${sanitizedEmail}/Tools/${datePath}/${img.id}.png`;
              return {
                ...img,
                originalUrl: newUrl
              };
            }
            return img;
          });
          
          // Log the updated image information
          console.log('=== Generated Image Information ===');
          console.log('New Image:', {
            id: newComposedImage.id,
            url: newComposedImage.url,
            originalUrl: updatedImages[0].originalUrl, // This will show the new format
            isSelected: newComposedImage.isSelected,
            isLiked: newComposedImage.isLiked
          });
          console.log('Total Images:', updatedImages.length);
          console.log('All Images:', updatedImages.map(img => ({
            id: img.id,
            url: img.url,
            originalUrl: img.originalUrl,
            isSelected: img.isSelected,
            isLiked: img.isLiked
          })));
          
          return updatedImages;
        });
        
        toast.success('이미지가 성공적으로 생성되었습니다.');

        // **4. 이미지 생성 성공 후 크레딧 차감**
        await updateCredit(1); // 크레딧 1 차감
      } else {
        setError('서버로부터 예상치 못한 응답을 받았습니다.');
        toast.error('이미지 생성에 실패했습니다. 다시 시도해주세요.');
      }
    } catch (err) {
      console.error('이미지 생성 중 오류 발생:', err);
      setError('이미지 생성에 실패했습니다. 다시 시도해주세요.');
      toast.error('이미지 생성 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  // 편집 결과 이미지 박스
  const processedImages = images.filter(
    (img) => img.processedUrl && !img.isProcessing
  );

  // 선택된 이미지들의 ID
  const selectedImageIds = composeProcessedImages
    .filter((img) => img.isSelected)
    .map((img) => img.id);

  const handleMouseEnter = (id: string) => {
    setComposeProcessedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, showOverlay: true } : img
      )
    );
    console.log('Mouse Enter:', id);
    console.log('Images after Mouse Enter:', composeProcessedImages);
  };
  
  const handleMouseLeave = (id: string) => {
    setComposeProcessedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id && !img.isSelected ? { ...img, showOverlay: false } : img
      )
    );
    console.log('Mouse Leave:', id);
    console.log('Images after Mouse Leave:', composeProcessedImages);
  };
  
  const handleSelectComposedImage = (id: string) => {
    setComposeProcessedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id
          ? { ...img, isSelected: !img.isSelected }
          : img
      )
    );
    console.log('Select Image:', id);
    console.log('Images after Select:', composeProcessedImages);
  };

  // Delete 핸들러
  const handleDeleteSelected = async () => {
    if (selectedImageIds.length === 0) {
      toast.warning('삭제할 이미지를 선택해주세요.');
      return;
    }
  
    const imagesToDelete = composeProcessedImages.filter(img => img.isSelected);

    // try {
    //   await Promise.all(imagesToDelete.map(async (img) => {
    //     console.log("#### original image url : ", img.originalUrl)
    //     await deleteImageOnServer(img.originalUrl);
    //   }));

    try {
      await Promise.all(imagesToDelete.map(async (img) => {
        console.log("#### Processed image url : ", img.url); // Updated log
        await deleteImageOnServer(img.url); // Use img.url instead of img.originalUrl
      }));

      setComposeProcessedImages(prevImages => prevImages.filter(img => !img.isSelected));
      toast.success('선택된 이미지가 삭제되었습니다.');
    } catch (error) {
      toast.error('선택된 이미지를 삭제하는 데 실패했습니다.');
    }
  };

  // Download 핸들러
  const handleDownloadSelected = () => {
    if (selectedImageIds.length === 0) {
      toast.warning('다운로드할 이미지를 선택해주세요.');
      return;
    }

    composeProcessedImages
      .filter((img) => img.isSelected)
      .forEach((img) => {
        const link = document.createElement('a');
        link.href = img.url;
        link.download = `composed_image_${img.id}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    toast.success('선택된 이미지가 다운로드되었습니다.');
  };

  // 전체 화면 모드에서 사용되는 Delete 및 Download 핸들러
  const handleFullscreenDeleteSelected = async () => {
    const currentIndex = fullscreenOverlay.currentImageIndex;
    if (currentIndex === -1) {
      toast.warning('삭제할 이미지를 선택해주세요.');
      return;
    }
  
    const selectedImage = composeProcessedImages[currentIndex];
    if (!selectedImage) {
      toast.error('삭제할 이미지가 없습니다.');
      return;
    }
  
    try {
      await deleteImageOnServer(selectedImage.originalUrl);

      const updatedImages = [...composeProcessedImages];
      updatedImages.splice(currentIndex, 1);
      setComposeProcessedImages(updatedImages);

      if (updatedImages.length > 0) {
        const nextIndex = currentIndex < updatedImages.length ? currentIndex : updatedImages.length - 1;
        setFullscreenOverlay({
          open: true,
          currentImageIndex: nextIndex,
        });
      } else {
        setFullscreenOverlay({
          open: false,
          currentImageIndex: -1,
        });
      }

      toast.success('선택된 이미지가 삭제되었습니다.');
    } catch (error) {
      toast.error('선택된 이미지를 삭제하는 데 실패했습니다.');
    }
  };

  const handleFullscreenDownloadSelected = () => {
    if (fullscreenOverlay.currentImageIndex === -1) {
      toast.warning('다운로드할 이미지를 선택해주세요.');
      return;
    }

    const selectedImage = composeProcessedImages[fullscreenOverlay.currentImageIndex];
    if (selectedImage) {
      saveAs(selectedImage.url, `composed_image_${selectedImage.id}.png`);
      toast.success('선택된 이미지가 다운로드되었습니다.');
    }
  };

  const handleCreateImageClick = () => {
    openDropzoneMain();
  };

  const anySelected = composeProcessedImages.some((img) => img.isSelected);

  // 전체 오버레이 열기
  const openFullscreenOverlay = (id: string) => {
    const index = composeProcessedImages.findIndex((img) => img.id === id);
    if (index !== -1) {
      setFullscreenOverlay({
        open: true,
        currentImageIndex: index,
      });
    }
  };

  // 전체 오버레이 닫기
  const closeFullscreenOverlay = () => {
    setFullscreenOverlay({
      open: false,
      currentImageIndex: fullscreenOverlay.currentImageIndex,
    });
  };

  // 좌측 화살표 클릭 핸들러
  const handlePrevImage = () => {
    setFullscreenOverlay((prev) => ({
      ...prev,
      currentImageIndex:
        prev.currentImageIndex === 0
          ? composeProcessedImages.length - 1
          : prev.currentImageIndex - 1,
    }));
  };

  // 우측 화살표 클릭 핸들러
  const handleNextImage = () => {
    setFullscreenOverlay((prev) => ({
      ...prev,
      currentImageIndex:
        prev.currentImageIndex === composeProcessedImages.length - 1
          ? 0
          : prev.currentImageIndex + 1,
    }));
  };

  // Replace Product 핸들러 (기존 핸들러 유지)
  const handleReplaceProduct = () => {
    toast.info('Replace Product 버튼이 클릭되었습니다.');
  };

  // ImageGenerator의 서브메뉴에 따라 UI 요소를 제어하는 함수
  const getDisplayConfig = () => {
    // Define which elements to show/hide based on selectedSubMenu
    const config: { [key: string]: Partial<DisplayConfig> } = {
      'SDXL-Lightning': {
        showFileUpload: false,
        showSecondFileUpload: false, // Show second upload button
        showFileViewer: false,
        showSecondFileViewer: false, // Show second viewer
        showPromptInput1: true,
        showPromptInput2: true,
        showImageListViewer: true,
        showGenerateButton: true,
        showAdvancedOptions: true, // New property
        availableOptions: submenuConfig['SDXL-Lightning'].availableOptions || [],
      },
      'FLUX.1-schnell': {
        showFileUpload: true,
        showSecondFileUpload: true,
        showFileViewer: true,
        showSecondFileViewer: true,
        showPromptInput1: true,
        showPromptInput2: true,
        showImageListViewer: true,
        showGenerateButton: true,
        showAdvancedOptions: false,
      },
      'PixMon-bg-remove': {
        showFileUpload: true,
        showSecondFileUpload: false,
        showFileViewer: true,
        showSecondFileViewer: false,
        showPromptInput1: false,
        showPromptInput2: false,
        showImageListViewer: true,
        showGenerateButton: true,
        showAdvancedOptions: false,
      },
      'PixMon-SDXL-bg-change': {
        showFileUpload: true,
        showSecondFileUpload: false,
        showFileViewer: true,
        showSecondFileViewer: false,
        showPromptInput1: true,
        showPromptInput2: false,
        showImageListViewer: true,
        showGenerateButton: true,
        showAdvancedOptions: false,
      },
      'Text-canny-controlnet': {
        showFileUpload: true,
        showSecondFileUpload: false,
        showFileViewer: true,
        showSecondFileViewer: false,
        showPromptInput1: true,
        showPromptInput2: true,
        showImageListViewer: true,
        showGenerateButton: true,
        showAdvancedOptions: false,
      },
      'AI outpainting': {
        showFileUpload: true,
        showSecondFileUpload: false,
        showFileViewer: true,
        showSecondFileViewer: false,
        showPromptInput1: true,
        showPromptInput2: false,
        showImageListViewer: true,
        showGenerateButton: true,
        showAdvancedOptions: true, // New property
        availableOptions: submenuConfig['AI outpainting'].availableOptions || [],
      },
      'PixMon-leffa': {
        showFileUpload: true,
        showSecondFileUpload: true,
        showFileViewer: true,
        showSecondFileViewer: true,
        showPromptInput1: false,
        showPromptInput2: false,
        showImageListViewer: true,
        showGenerateButton: true,
        showAdvancedOptions: false,
      },
      // Add configurations for other submenus as needed
    };

    // Default configuration if submenu is not defined
    const defaultConfig: Partial<DisplayConfig> = {
      showFileUpload: true,
      showSecondFileUpload: true,
      showFileViewer: true,
      showSecondFileViewer: true,
      showPromptInput1: true,
      showPromptInput2: true,
      showImageListViewer: true,
      showGenerateButton: true,
    };

    return selectedSubMenu ? (config[selectedSubMenu] || defaultConfig) : defaultConfig;
  };

  interface DisplayConfig {
    showFileUpload: boolean;
    showSecondFileUpload: boolean;
    showFileViewer: boolean;
    showSecondFileViewer: boolean;
    showPromptInput1: boolean;
    showPromptInput2: boolean;
    showImageListViewer: boolean;
    showGenerateButton: boolean;
    showAdvancedOptions: boolean; // New property to control advanced options visibility
    availableOptions?: string[]; // New property to specify available options
  }

  const displayConfig = getDisplayConfig();

  // State for toggling advanced options visibility
  const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false);

  // Reset the advanced options toggle when submenu changes
  useEffect(() => {
    if (displayConfig.showAdvancedOptions) {
      setShowAdvancedOptions(false);
    }
  }, [selectedSubMenu, displayConfig.showAdvancedOptions]);

  // State for option fields (only those available)
  const [options, setOptionsState] = useState<{
    [key: string]: number;
  }>({
    width: 1024,
    height: 1024,
    x_percent: 75,
    y_percent: 76,
  });

  // Handle option field changes
  const handleOptionChange = (option: string, value: number) => {
    setOptionsState((prev) => ({
      ...prev,
      [option]: value,
    }));
  };


  // State variables for Share Dialog
  const [openShareDialog, setOpenShareDialog] = useState<boolean>(false);
  const [shareDescription, setShareDescription] = useState<string>('');
  const [shareTags, setShareTags] = useState<string>('');
  const [selectedShareImage, setSelectedShareImage] = useState<ComposedImageData | null>(null);
  const [shareTargetImage, setShareTargetImage] = useState<ComposedImageData | null>(null); 

  // Handler to toggle like status and update server
  const handleLike = async (imageId: string) => {
    try {
      // Find the image
      const image = composeProcessedImages.find(img => img.id === imageId);
      if (!image) {
        toast.error('이미지를 찾을 수 없습니다.');
        return;
      }

      // Toggle like status locally
      setComposeProcessedImages((prevImages) =>
        prevImages.map((img) =>
          img.id === imageId ? { ...img, isLiked: !img.isLiked } : img
        )
      );

      // Send like status to server
      const response = await axiosInstance.post('/like-image', {
        imageId: image.id,
        like: !image.isLiked,
      }, { withCredentials: true });

      if (response.status === 200) {
        toast.success(`이미지가 ${!image.isLiked ? '좋아요' : '좋아요 취소'}되었습니다.`);
      } else {
        // Revert the like status in case of failure
        setComposeProcessedImages((prevImages) =>
          prevImages.map((img) =>
            img.id === imageId ? { ...img, isLiked: image.isLiked } : img
          )
        );
        toast.error('좋아요 상태를 업데이트하는 데 실패했습니다.');
      }
    } catch (error) {
      console.error('좋아요 업데이트 실패:', error);
      // Revert the like status in case of error
      setComposeProcessedImages((prevImages) =>
        prevImages.map((img) =>
          img.id === imageId ? { ...img, isLiked: !img.isLiked } : img
        )
      );
      toast.error('좋아요 상태를 업데이트하는 데 실패했습니다.');
    }
  };

  // Handler to open Share Dialog
  const handleShare = (image: ComposedImageData) => {
    setSelectedShareImage(image);
    setShareDescription('');
    setShareTags('');
    setOpenShareDialog(true);
  };

  // Handler to close Share Dialog
  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
    setSelectedShareImage(null);
    setShareDescription('');
    setShareTags('');
  };

  // // Handler to submit share
  // const handleSubmitShare = async () => {
  //   if (!selectedShareImage) {
  //     toast.error('공유할 이미지를 선택해주세요.');
  //     return;
  //   }

  //   try {
  //     const response = await axiosInstance.post('/gallery/share', {
  //       image_url: selectedShareImage.originalUrl,
  //       description: shareDescription,
  //       tags: shareTags.split(',').map(tag => tag.trim()),
  //     }, { withCredentials: true });

  //     if (response.status === 200) {
  //       toast.success('이미지가 성공적으로 공유되었습니다.');
  //       handleCloseShareDialog();
  //     } else {
  //       toast.error('이미지 공유에 실패했습니다. 다시 시도해주세요.');
  //     }
  //   } catch (error) {
  //     console.error('이미지 공유 실패:', error);
  //     toast.error('이미지 공유에 실패했습니다. 다시 시도해주세요.');
  //   }
  // };

  // 실제 서버에 공유 요청 (POST /gallery/share)
  const handleSubmitShare = async () => {
    if (!selectedShareImage) {
      console.log("shareTargetImage not found")
      return;
    }

    try {
      // file_path = shareTargetImage.originalUrl (혹은 server accessible URL)
      const payload = {
        // file_path: shareTargetImage.originalUrl, 
        image_url: selectedShareImage.originalUrl, 
        description: shareDescription,
        tags: shareTags,
        // tags: shareTags.split(',').map(tag => tag.trim()),
      };
      const response = await axiosInstance.post('/gallery/share', payload);
      if (response.status === 200) {
        toast.success('Successfully shared the image!');
        // 모달 닫기
        handleCloseShareDialog();
      } else {
        toast.error('Failed to share the image.');
      }
    } catch (error) {
      console.error('Share failed:', error);
      toast.error('Failed to share the image.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* 새로운 섹션: 선택된 서브메뉴의 타이틀과 설명 */}
      {selectedSubMenu && submenuConfig[selectedSubMenu] && (
        <Box sx={{ mb: 2, borderBottom: '1px solid #ddd', pb: 1 }}>
          {/* Flex Container for Title and Icon */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h1" sx={{ color: '#666666' }}>
              {submenuConfig[selectedSubMenu].title}
            </Typography>
            <Tooltip title={isTitleExpanded ? 'Collapse' : 'Expand'}>
              <IconButton
                onClick={() => setIsTitleExpanded(!isTitleExpanded)}
                aria-label={isTitleExpanded ? 'Collapse title and description' : 'Expand title and description'}
                sx={{
                  transform: isTitleExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              >
                {isTitleExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Tooltip>
          </Box>

          {/* Collapse Component for Description */}
          <Collapse in={isTitleExpanded} timeout="auto" unmountOnExit>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {submenuConfig[selectedSubMenu].description}
            </Typography>
          </Collapse>
        </Box>
      )}

      {/* Upload Buttons and Viewers */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {/* First Upload Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {displayConfig.showFileUpload && (
              <Fade in={true} timeout={500}>
                {/* <Button variant="contained" color="primary" onClick={openDropzoneMain}> */}
                <Button variant="contained" color="primary" onClick={handleCreateImageClick} disabled={isLoading}>
                  Photo Upload
                </Button>
              </Fade>
            )}
            {/* Image Viewer */}
            {displayConfig.showFileViewer && (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '100%',
                  aspectRatio: '1 / 1',
                  backgroundColor: '#fafafa',
                  backgroundImage: `linear-gradient(45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                                    linear-gradient(-45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                                    linear-gradient(45deg, transparent 75%, ${theme.palette.grey[300]} 75%),
                                    linear-gradient(-45deg, transparent 75%, ${theme.palette.grey[300]} 75%)`,
                  backgroundSize: '20px 20px',
                  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
                  border: '2px dashed #FFEB3B',
                  borderRadius: 1,
                  // borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[6],
                  overflow: 'auto',
                  marginTop: 2,
                  cursor: 'pointer',
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  {getViewerBoxImageUrl() ? (
                    <Fade in={true} timeout={500}>
                      <StyledImage
                        src={getViewerBoxImageUrl()!}
                        alt={`Viewer Image ${selectedImage ? selectedImage.id : 'Default'}`}
                        scale={scale}
                      />
                    </Fade>
                  ) : (
                    <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                      이미지를 업로드하거나 선택해 주세요.
                    </Typography>
                  )}
                </Box>
                {/* Loading Overlay */}
                {(images.some((img) => img.isProcessing) || isLoading) && (
                  <LoadingOverlay>
                    <CircularProgress />
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Processing...
                    </Typography>
                  </LoadingOverlay>
                )}
              </Box>
            )}
          </Box>
        </Grid>

        {/* Second Upload Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {displayConfig.showSecondFileUpload && (
              <Fade in={true} timeout={500}>
                <Button variant="contained" color="secondary" onClick={openSecondDropzone} disabled={isLoading}>
                  Second Photo Upload
                </Button>
              </Fade>
            )}
            {/* Second Image Viewer */}
            {displayConfig.showSecondFileViewer && (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '100%',
                  aspectRatio: '1 / 1',
                  backgroundColor: '#fafafa',
                  backgroundImage: `linear-gradient(45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                                    linear-gradient(-45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                                    linear-gradient(45deg, transparent 75%, ${theme.palette.grey[300]} 75%),
                                    linear-gradient(-45deg, transparent 75%, ${theme.palette.grey[300]} 75%)`,
                  backgroundSize: '20px 20px',
                  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
                  border: '2px dashed #FFEB3B',
                  borderRadius: 1,
                  // borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[6],
                  overflow: 'auto',
                  marginTop: 2,
                  cursor: 'pointer',
                }}
                {...getSecondRootProps()}
              >
                <input {...getSecondInputProps()} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  {secondImages.length > 0 ? (
                    <Fade in={true} timeout={500}>
                      <StyledImage
                        src={secondImages[secondImages.length - 1].processedUrl || secondImages[secondImages.length - 1].originalUrl}
                        alt={`Second Viewer Image ${secondImages[secondImages.length - 1].id}`}
                        scale={scale}
                      />
                    </Fade>
                  ) : (
                    <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                      두 번째 이미지를 업로드하세요.
                    </Typography>
                  )}
                </Box>
                {/* Loading Overlay */}
                {(secondImages.some((img) => img.isProcessing) || isLoading) && (
                  <LoadingOverlay>
                    <CircularProgress />
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Processing...
                    </Typography>
                  </LoadingOverlay>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Prompt Inputs */}
      <Box sx={{ mb: 4 }}>
        {displayConfig.showPromptInput1 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">Positive Prompt</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={positivePromptInput}
              onChange={(e) => setPositivePromptInput(e.target.value)}
              placeholder="Enter positive prompt"
            />
          </Box>
        )}
        {displayConfig.showPromptInput2 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">Negative Prompt</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={negativePromptInput}
              onChange={(e) => setNegativePromptInput(e.target.value)}
              placeholder="Enter negative prompt"
            />
          </Box>
        )}
      </Box>

      {/* Toggle Switch for Advanced Options */}
      {displayConfig.showAdvancedOptions && displayConfig.availableOptions && (
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                checked={showAdvancedOptions}
                onChange={() => setShowAdvancedOptions((prev) => !prev)}
                color="primary"
              />
            }
            label="Show Additional Options"
          />
        </Box>
      )}

      {/* Advanced Options Fields */}
      {showAdvancedOptions && displayConfig.availableOptions && (
        <Box sx={{ mb: 4, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            Additional Options
          </Typography>
          <Grid container spacing={2}>
            {displayConfig.availableOptions.includes('width') && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Width (px)"
                  type="number"
                  fullWidth
                  value={options.width}
                  onChange={(e) =>
                    handleOptionChange('width', parseInt(e.target.value, 10) || 0)
                  }
                  inputProps={{ min: 1 }}
                />
              </Grid>
            )}

            {displayConfig.availableOptions.includes('height') && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Height (px)"
                  type="number"
                  fullWidth
                  value={options.height}
                  onChange={(e) =>
                    handleOptionChange('height', parseInt(e.target.value, 10) || 0)
                  }
                  inputProps={{ min: 1 }}
                />
              </Grid>
            )}

            {displayConfig.availableOptions.includes('x_percent') && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="X Percent (%)"
                  type="number"
                  fullWidth
                  value={options.x_percent}
                  onChange={(e) =>
                    handleOptionChange('x_percent', parseInt(e.target.value, 10) || 0)
                  }
                  inputProps={{ min: 1, max: 200 }}
                />
              </Grid>
            )}

            {displayConfig.availableOptions.includes('y_percent') && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Y Percent (%)"
                  type="number"
                  fullWidth
                  value={options.y_percent}
                  onChange={(e) =>
                    handleOptionChange('y_percent', parseInt(e.target.value, 10) || 0)
                  }
                  inputProps={{ min: 1, max: 200 }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}


      {/* 이미지 뷰어와 로딩 오버레이 */}
      <Box sx={{ position: 'relative', mb: 4 }}>
        {/* Existing viewer can be placed here if needed */}
      </Box>

      {/* 편집 결과 이미지 박스 */}
      <EditingResultsBox>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, flexWrap: 'wrap', gap: 2 }}>
          {anySelected ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteSelected}
                disabled={isLoading}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadSelected}
                disabled={isLoading}
              >
                Download
              </Button>
            </Box>
          ) : (
            <>
              <Typography variant="h6">Recent</Typography>
              {displayConfig.showGenerateButton && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleGenerateButton}
                  disabled={isLoading || !userProfile || userProfile.credit <= 0} // Disable button when loading
                >
                  {isLoading ? 'Generating...' : 'Generate'} {/* Optional: Change button text */}
                </Button>
              )}
            </>
          )}
        </Box>

        {/* ImageList with border */}
        {displayConfig.showImageListViewer && (
          <InfiniteScroll
            dataLength={composeProcessedImages.length}
            next={fetchImages}
            hasMore={hasMore}
            loader={
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            }
            endMessage={
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                더 이상 이미지를 로드할 수 없습니다.
              </Typography>
            }
          >
            <ImageList 
              cols={isMobile ? 2 : 5}
              gap={8} 
              sx={{ 
                mt: 2, 
                border: '2px dashed #FFEB3B', 
                borderRadius: 1, 
                padding: 2,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                minHeight: '200px' 
              }}
            >
              {composeProcessedImages.length > 0 ? (
                composeProcessedImages.map((img, index) => (
                  <ImageListItem 
                    key={img.id} 
                    sx={{ 
                      width: itemSize, 
                      height: itemSize, 
                      position: 'relative', 
                      '&:hover': { boxShadow: 3 },
                      cursor: 'zoom-in',
                    }}
                    onMouseEnter={() => handleMouseEnter(img.id)}
                    onMouseLeave={() => handleMouseLeave(img.id)}
                    onClick={() => handleImageListClick(img)} // 이미지 클릭 시 선택
                  >
                    <Card sx={{ width: '100%', height: '100%' }}>
                      <CardMedia
                        component="img"
                        image={img.url}
                        alt={`Composed Image ${img.id}`}
                        sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                      {img.showOverlay && (
                        <Box
                          className="overlay"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            padding: 1,
                            boxSizing: 'border-box',
                            cursor: 'zoom-in',
                          }}
                          onClick={() => openFullscreenOverlay(img.id)}
                        >

                            <Checkbox
                              checked={img.isSelected}
                              onChange={() => handleSelectComposedImage(img.id)}
                              sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                borderRadius: '50%',
                                width: 20,
                                height: 20,
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />

                          <Box sx={{ display: 'flex', gap: 1 }}>
                          <IconButton
                            aria-label="download"
                            onClick={(e) => {
                              e.stopPropagation();
                              const link = document.createElement('a');
                              link.href = img.url;
                              link.download = `composed_image_${img.id}.png`;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.7)',
                              width: 24,
                              height: 24,
                              padding: 0,
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                          >
                            <DownloadIcon fontSize="small" />
                          </IconButton>

                            <IconButton
                              aria-label="like"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLike(img.id);
                              }}
                              sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                width: 24,
                                height: 24,
                                padding: 0,
                              }}
                            >
                              {img.isLiked ? <FavoriteIcon fontSize="small" color="error" /> : <FavoriteBorderIcon fontSize="small" sx={{ color: '#fff' }} />}
                            </IconButton>
                            <IconButton
                              aria-label="share"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleShare(img);
                              }}
                              sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                width: 24,
                                height: 24,
                                padding: 0,
                              }}
                            >
                              <ShareIcon fontSize="small" />
                            </IconButton>
                  
                          </Box>
                        </Box>
                      )}
                    </Card>
                  </ImageListItem>
                ))
              ) : (
                <ImageListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px', width: '100%' }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      No image yet!!
                    </Typography>
                    <Button variant="contained" color="primary" onClick={openDropzoneMain} disabled={isLoading || !userProfile || userProfile.credit <= 0}>
                      Create your first image
                    </Button>
                  </Box>
                </ImageListItem>
              )}
            </ImageList>
          </InfiniteScroll>

        )}
      </EditingResultsBox>

      {/* 전체 오버레이 모달 */}
      <Modal
        open={fullscreenOverlay.open}
        onClose={closeFullscreenOverlay}
        aria-labelledby="fullscreen-overlay-title"
        aria-describedby="fullscreen-overlay-description"
      >
        <FullscreenOverlay>
          <OverlayContent isMobile={isMobile}>
            {/* 화살표 버튼 */}
            {!isMobile && (
              <>
                <ArrowButton
                  onClick={handlePrevImage}
                  sx={{ left: 0 }}
                >
                  <ArrowBackIosIcon />
                </ArrowButton>
                <ArrowButton
                  onClick={handleNextImage}
                  sx={{ right: 0 }}
                >
                  <ArrowForwardIosIcon />
                </ArrowButton>
              </>
            )}
            {isMobile && (
              <>
                <ArrowButton
                  onClick={handlePrevImage}
                  sx={{ left: '10px' }}
                >
                  <ArrowBackIosIcon />
                </ArrowButton>
                <ArrowButton
                  onClick={handleNextImage}
                  sx={{ right: '10px' }}
                >
                  <ArrowForwardIosIcon />
                </ArrowButton>
              </>
            )}

            {/* 닫기 버튼 */}
            <CloseButton onClick={closeFullscreenOverlay}>
              <CloseIcon />
            </CloseButton>

            {/* 센터 콘텐츠 */}
            <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
              {/* 좌측: 선택된 이미지 */}
              <Box sx={{ flex: 1, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  src={composeProcessedImages[fullscreenOverlay.currentImageIndex]?.url}
                  alt={`Selected Image ${composeProcessedImages[fullscreenOverlay.currentImageIndex]?.id}`}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </Box>

              {/* 우측: 상단과 하단으로 분리 */}
              <Box sx={{ flex: 1, padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* 상단 텍스트 출력 영역 */}
                <Box sx={{ flexBasis: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                  <Typography variant="h6" gutterBottom>
                    {describeText || 'No description provided.'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {dontWantText || 'No additional description.'}
                  </Typography>
                  {selectedCustomImage && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <img
                        src={selectedCustomImage}
                        alt="Reference"
                        style={{ maxWidth: '100%', maxHeight: '150px' }}
                      />
                    </Box>
                  )}
                </Box>

                {/* 대시 라인 */}
                <Divider sx={{ width: '80%', my: 2 }} />

                {/* 하단: 버튼 2개 */}
                <Box sx={{ flexBasis: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mr: isMobile ? 0 : 16 }}>
                  <ButtonGroupContainer container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<DeleteIcon />}
                        onClick={handleFullscreenDeleteSelected}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                        disabled={isLoading} // Disable during loading
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<DownloadIcon />}
                        onClick={handleFullscreenDownloadSelected}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                        disabled={isLoading} // Disable during loading
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<SwapHorizIcon />}
                        onClick={handleReplaceProduct}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                        disabled={isLoading} // Disable during loading
                      >
                        Replace
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<ZoomInIcon />}
                        onClick={() => toast.info('Zoom 기능은 현재 지원되지 않습니다.')}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                        disabled={isLoading} // Disable during loading
                      >
                        Zoom
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<AddPhotoAlternateIcon />}
                        onClick={() => toast.info('Add Photo 기능은 현재 지원되지 않습니다.')}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                        disabled={isLoading} // Disable during loading
                      >
                        Add Photo
                      </Button>
                    </Grid>
                  </ButtonGroupContainer>
                </Box>
              </Box>
            </Box>
          </OverlayContent>
        </FullscreenOverlay>
      </Modal>

      {/* Share Dialog */}
      <Dialog open={openShareDialog} onClose={handleCloseShareDialog} maxWidth="sm" fullWidth>
        <DialogTitle>이미지 공유</DialogTitle>
        <DialogContent>
          <TextField
            label="설명"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            value={shareDescription}
            onChange={(e) => setShareDescription(e.target.value)}
          />
          <TextField
            label="태그"
            variant="outlined"
            fullWidth
            margin="normal"
            value={shareTags}
            onChange={(e) => setShareTags(e.target.value)}
            helperText="쉼표(,)로 구분하거나 자유롭게 입력하세요."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog}>취소</Button>
          <Button onClick={handleSubmitShare} variant="contained" color="primary">
            공유
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading Backdrop */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={isLoading}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Generating images...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default ImageGenerator;
