// src/pages/Login.tsx

import React, { useState } from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { Link, useNavigate, useLocation } from "react-router-dom"; // useLocation 임포트
import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import "../styles/auth.css";
import axiosInstance from '../api/axios'; // axiosInstance 임포트
import { toast } from 'react-toastify'; // Toastify 임포트 (추가)

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const navigate = useNavigate();
    const location = useLocation(); // 현재 위치 가져오기

    // 원래 요청한 경로 가져오기
    const from = (location.state as { from: { pathname: string } })?.from?.pathname || "/";

    const handleLoginSuccess = async () => {
        try {
            // 현재 사용자의 ID 토큰 획득
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
            const idToken = await user.getIdToken();

            // 백엔드의 /me 엔드포인트 호출
            const response = await axiosInstance.get("/me", {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });

            // 응답이 성공적이면 원래 요청한 경로로 이동
            navigate(from, { replace: true });
            toast.success("로그인에 성공했습니다!");
        } catch (err: any) {
            // console.error("Error during /me request:", err);
            setError("로그인 후 사용자 정보를 가져오는 데 실패했습니다.");
        }
    };

    const handleEmailLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(""); // 기존 에러 상태 초기화
    
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            if (user) {
                // 사용자 데이터를 최신 상태로 새로고침
                await user.reload();
    
                // 이메일 인증 여부 확인
                if (!user.emailVerified) {
                    throw new Error("이메일이 인증되지 않았습니다. 이메일을 확인하고 인증 후 로그인해주세요.");
                }
    
                // 이메일 인증 성공 시 로그인 처리
                await handleLoginSuccess();
            }
        } catch (err: any) {
            // console.error("Email login error:", err);
            setError(err.message || "로그인에 실패했습니다. 다시 시도해주세요.");
        }
    };

    const handleGoogleLogin = async () => {
        setError("");
        try {
            const result = await signInWithPopup(auth, googleProvider);
            if (result.user) {
                await handleLoginSuccess(); // 로그인 성공 후 처리
            }
        } catch (err: any) {
            console.error("Google login error:", err);
            setError(err.message);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Login
                </Typography>
                {error && <Typography color="error" variant="body2" align="center">{error}</Typography>}

                <form onSubmit={handleEmailLogin} style={{ marginTop: '16px' }}>
                    <Box mb={2}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ py: 1.5 }}>
                        Login
                    </Button>
                </form>

                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleGoogleLogin}
                    sx={{ mt: 2, py: 1.5 }}
                >
                    Google Login
                </Button>

                <Box mt={2} textAlign="center">
                    <Typography variant="body2">
                        Forgot your password? <Link to="/forgot-password">Reset Password</Link>
                    </Typography>
                    <Typography variant="body2">
                        Don't have an account? <Link to="/signup">Signup</Link>
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default Login;
