import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from '@mui/material';
import Navbar from '../components/Navbar';
import { useAuth } from '../contexts/AuthContext'; // 인증 컨텍스트 임포트
import { useNavigate } from 'react-router-dom'; // useNavigate 훅 임포트

const plans = [
  {
    title: '1,000 Images',
    price: 30,
    credits: 1000,
    description: 'Pay as you want to by credit. 1 credit = 1 image generation.',
  },
  {
    title: '2,000 Images',
    price: 50,
    credits: 2000,
    description: 'Pay as you want to by credit. 1 credit = 1 image generation.',
  },
  {
    title: '5,000 Images',
    price: 100,
    credits: 5000,
    description: 'Pay as you want to by credit. 1 credit = 1 image generation.',
  },
];

const PricingPage: React.FC = () => {
  const { currentUser } = useAuth(); // 인증 정보 가져오기
  const navigate = useNavigate(); // 페이지 이동을 위한 navigate 함수

  const [authChecked, setAuthChecked] = useState<boolean>(false); // 인증 체크 상태
  const [loading, setLoading] = useState<boolean>(true); // 로딩 상태

  useEffect(() => {
    const checkAuth = async () => {
      if (currentUser) {
        // 사용자가 인증된 경우
        setAuthChecked(true);
      } else {
        // 사용자가 인증되지 않은 경우 로그인 페이지로 리디렉션
        navigate('/login', { replace: true });
      }
      setLoading(false);
    };

    checkAuth();
  }, [currentUser, navigate]);

  // handlePurchase 함수 정의
  const handlePurchase = (credits: number, price: number) => {
    // 결제 페이지로 이동하면서 선택한 요금제 정보를 전달
    navigate('/payment', { state: { credits, price } });
  };

  if (loading) {
    // 인증 상태를 확인 중인 경우 로딩 스피너 표시
    return (
      <>
        <Navbar />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  // 인증이 확인되고 사용자가 인증된 경우 요금제 페이지 렌더링
  return (
    <>
      <Navbar />
      <Box sx={{ p: 4, mt: { xs: 8, sm: 10 } }}>
        {/* Navbar 높이에 따라 적절한 marginTop 값 설정 */}
        <Typography variant="h4" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Choose the plan that works best for your needs.
        </Typography>

        <Grid container spacing={4}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  textAlign: 'center',
                  boxShadow: 3,
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {plan.title}
                  </Typography>
                  <Typography variant="h4" color="primary" gutterBottom>
                    ${plan.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    {plan.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePurchase(plan.credits, plan.price)}
                  >
                    Buy Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default PricingPage;
