// src/components/DynamicComponent.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';
import SDXLLightning from './TabsContent/SDXLLightning';
import Flux1Schnell from './TabsContent/Flux1Schnell';
import PixMonPixartSigma from './TabsContent/PixMonPixartSigma';
// 필요한 다른 컴포넌트들도 임포트

interface DynamicComponentProps {
  selectedMenu: string;
  selectedSubMenu: string;
}

const DynamicComponent: React.FC<DynamicComponentProps> = ({ selectedMenu, selectedSubMenu }) => {
  // Tools 메뉴가 아닌 경우에만 DynamicComponent가 호출됨
  // 따라서 Tools 관련 서브메뉴는 여기서 처리하지 않음

  switch (selectedSubMenu) {
    case 'SDXL-Lightning':
      return <SDXLLightning />;
    case 'FLUX.1-schnell':
      return <Flux1Schnell />;
    case 'PixMon-pixart-sigma':
      return <PixMonPixartSigma />;
    // 다른 서브메뉴에 해당하는 컴포넌트 추가
    default:
      return (
        <Box>
          <Typography variant="h6">선택된 기능: {selectedSubMenu}</Typography>
          {/* 기본 내용을 추가하거나, 원하는 컴포넌트를 렌더링 */}
        </Box>
      );
  }
};

export default DynamicComponent;
