// src/App.tsx
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./pages/Home";
import TopPage from "./pages/TopPage";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./components/Layout";
import ProtectedPage from "./pages/ProtectedPage";
import { FolderProvider } from './contexts/FolderContext';
import { PromptProvider } from './contexts/PromptContext';
import Subscribe from './pages/Subscribe';
import Profile from './pages/Profile';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import PaymentRedirect from './pages/PaymentRedirect';
import Gallery from './pages/Gallery';
import PricingPage from './pages/PricingPage';
import ProtectedRoute from './components/ProtectedRoute';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import CopyrightPage from './pages/CopyrightPage';
import SupportPage from './pages/SupportPage';

// **Import Auth and User Providers**
import { AuthProvider } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <UserProvider>
        <FolderProvider>
          <PromptProvider>
            <>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
              />

              <Routes>
                {/* Public Routes */}
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/success" element={<Success />} />
                <Route path="/cancel" element={<Cancel />} />
                <Route path="/payment-redirect" element={<PaymentRedirect />} />
                <Route path="/" element={<TopPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/copyright" element={<CopyrightPage />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/" element={<TopPage />} />


                {/* Protected Routes */}
                <Route
                  path="/pricing"
                  element={
                    <ProtectedRoute>
                      <PricingPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/gallery"
                  element={
                    <ProtectedRoute>
                      <Gallery />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tools"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Home />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/protected"
                  element={
                    <PrivateRoute>
                      <Layout>
                        <ProtectedPage />
                      </Layout>
                    </PrivateRoute>
                  }
                />
              </Routes>
            </>
          </PromptProvider>
        </FolderProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default App;
