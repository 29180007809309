// src/pages/Home.tsx
import React from "react";
import "../styles/auth.css"; // 스타일 임포트


const Home: React.FC = () => {
    return (
        <div className="home-container">
            <h1>환영합니다, PixMon에 오신 것을 환영합니다!</h1>
            {/* 추가적인 홈 페이지 콘텐츠 */}
        </div>
    );
};

export default Home;


// import React from 'react';
// import { Box, Typography, Grid, Card, CardContent } from '@mui/material';

// const Home: React.FC = () => {
//   return (
//     <Box>
//       <Typography variant="h1" gutterBottom>
//         Welcome to PixMon Store
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         Explore our tools and AI solutions.
//       </Typography>
//       <Grid container spacing={2}>
//         {[1, 2, 3, 4].map((item) => (
//           <Grid item xs={12} sm={6} md={3} key={item}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h5">Tool {item}</Typography>
//                 <Typography variant="body2">Description of the tool.</Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

// export default Home;

