import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import AssetIcon from '@mui/icons-material/PhotoLibrary';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/system';

interface SidebarProps {
  selectedMenu: string;
  setSelectedMenu: (menu: string) => void;
}

const menuItems = [
  { text: 'Create', icon: <CreateIcon sx={{ fontSize: 36 }} /> },
  { text: 'Assets', icon: <AssetIcon sx={{ fontSize: 36 }} /> },
  { text: 'Tools', icon: <BuildIcon sx={{ fontSize: 36 }} /> },
  { text: 'Settings', icon: <SettingsIcon sx={{ fontSize: 36 }} /> },
];

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingY: theme.spacing(2),
}));

const Sidebar: React.FC<SidebarProps> = ({ selectedMenu, setSelectedMenu }) => {
  const handleMenuClick = (menu: string) => {
    setSelectedMenu(menu);
  };

  return (
    <Box
      sx={{
        width: '80px',
        borderRight: '1px solid #ddd',
        height: '100%',
        backgroundColor: '#f5f5f5',
        overflow: 'auto',
      }}
    >
      <List component="nav">
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <StyledListItemButton
              selected={selectedMenu === item.text}
              onClick={() => handleMenuClick(item.text)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 'auto',
                  color: selectedMenu === item.text ? 'primary.main' : 'text.secondary',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="caption"
                    sx={{
                      marginTop: 1,
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      width: '100%',
                    }}
                  >
                    {item.text}
                  </Typography>
                }
                sx={{ textAlign: 'center' }}
              />
            </StyledListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
