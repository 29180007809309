// src/components/Layout.tsx
import React, { useState, useEffect } from 'react';
import { Box, Drawer, useMediaQuery, Theme } from '@mui/material';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import TabBar from './TabBar';
import ImageEditor from './ImageEditor';
import ImageGenerator from './ImageGenerator';
import Settings from './Settings'; // Settings 컴포넌트 임포트
import { useTheme } from '@mui/material/styles';
import { SelectedImage, BgFile } from '../types';
import { positivePrompts, negativePrompts } from '../prompts';
import DynamicComponent from './DynamicComponent';
import { ToolsProvider } from '../contexts/ToolsContext';
import backgroundPattern from '../assets/background-pattern.png';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState<string>('Create'); // 사이드바 메뉴 선택 상태
  const [selectedTab, setSelectedTab] = useState<string>('Auto'); // 탭바 탭 선택 상태
  const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(null);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false); // 모바일 드로어 상태
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // 이미지 관련 상태
  const [selectedImage, setSelectedImage] = useState<SelectedImage | null>(null);
  const [selectedCustomImage, setSelectedCustomImage] = useState<string | null>(null);

  // Prompt 관련 상태
  const [positivePrompt, setPositivePrompt] = useState<string>('');
  const [negativePrompt, setNegativePrompt] = useState<string>('');

  // 새로운 상태 추가: describeText, dontWantText, fetchedFileList
  const [describeText, setDescribeText] = useState<string>('');
  const [dontWantText, setDontWantText] = useState<string>('');
  const [fetchedFileList, setFetchedFileList] = useState<BgFile[]>([]);

  // 탭 변경 시 고유한 key 생성을 위한 상태
  const [tabKey, setTabKey] = useState<string>('Auto');

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
    if (!mobileOpen) {
      // 사이드바를 열 때 기본 메뉴를 활성화
      setSelectedSidebarMenu('Create');
      setSelectedTab('Auto');
      setTabKey(`Auto_${Date.now()}`); // 고유한 key 설정
      setSelectedSubMenu(null); // 메뉴 변경 시 서브메뉴 초기화
    }
  };

  // 사이드바 메뉴 클릭 시 호출되는 핸들러
  const handleSidebarMenuClick = (menu: string) => {
    setSelectedSidebarMenu(menu);
    setSelectedSubMenu(null); // 메뉴 변경 시 서브메뉴 초기화
    if (menu === 'Create') {
      setSelectedTab('Auto'); // 'Create' 선택 시 기본 탭을 'Auto'로 설정
      setTabKey(`Auto_${Date.now()}`); // 고유한 key 설정
    }
    // **Add condition for 'Assets' menu**
    if (menu === 'Assets') {
      setSelectedTab('Assets'); // 'Assets' 선택 시 탭을 'Assets'로 설정
      setTabKey(`Assets_${Date.now()}`); // 고유한 key 설정
    }
  };

  // 탭바 탭 변경 시 호출되는 핸들러
  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    setTabKey(`${tab}_${Date.now()}`); // 고유한 key 생성
  };

  // ThemesTab에서 이미지 선택 시 랜덤 프롬프트 설정 및 selectedCustomImage 업데이트
  const handleThemesImageSelect = (image: SelectedImage | null) => {
    if (image) {
      setSelectedCustomImage(image.url); // selectedCustomImage 업데이트
      setPositivePrompt(positivePrompts[Math.floor(Math.random() * positivePrompts.length)]);
      setNegativePrompt(negativePrompts[Math.floor(Math.random() * negativePrompts.length)]);
      console.log('Selected Custom Image:', image.url);
    } else {
      setSelectedCustomImage(null);
      setPositivePrompt('');
      setNegativePrompt('');
      console.log('Selected Custom Image cleared');
    }
  };

  // 탭 변경 시 상태 초기화
  useEffect(() => {
    setSelectedImage(null);
    setSelectedCustomImage(null);
    setPositivePrompt('');
    setNegativePrompt('');
    setDescribeText('');
    setDontWantText('');
    setFetchedFileList([]);
  }, [selectedTab]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100vh',
      backgroundColor: 'background.default',
      color: 'text.primary',
      padding: 0
      }}>
      <Navbar onMenuClick={handleDrawerToggle} />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: isSmallScreen ? 'column' : 'row',
          backgroundColor: 'background.default',
          color: 'text.primary',
          padding: 0
        }}
      >
        {/* 좌측 사이드바 */}
        {isSmallScreen ? (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // 모바일 성능 향상
            }}
            sx={{
              display: { xs: 'block', sm: 'none' }, // 모바일 환경에서만 드로어 활성화
              '& .MuiDrawer-paper': {
                width: '80px', // 넓은 폭으로 설정하여 메뉴가 한 번에 보이도록 조정
                backgroundColor: 'background.default',
                color: 'text.primary',
                padding: 0
              },
            }}
          >          
            <Sidebar 
              selectedMenu={selectedSidebarMenu} 
              setSelectedMenu={ (menu) => {
                handleSidebarMenuClick(menu);
                handleDrawerToggle();
                }} />
          </Drawer>
        ) : (
          <Box
            sx={{
              width: '80px', // 사이드바 폭 설정 (80px)
              flexShrink: 0,
              transition: 'width 0.3s',
              backgroundColor: 'background.default',
              color: 'text.primary',
              padding: 0
            }}
          >
            <Sidebar selectedMenu={selectedSidebarMenu} setSelectedMenu={handleSidebarMenuClick} />
          </Box>
        )}

        {/* 가운데 및 오른쪽 영역: 3-column or 2-column based on selectedSidebarMenu */}
        {selectedSidebarMenu === 'Settings' ? (
          // 2-column layout: Sidebar + Settings
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              overflowY: 'auto',
            }}
          >
            <Settings />
          </Box>
        ) : (
          // 3-column layout: TabBar + Right Component
          <>
            {/* 가운데 탭바 영역 */}
            <Box sx={{ 
              width: '300px', 
              flexShrink: 0, 
              backgroundColor: 'background.default',
              color: 'text.primary',
              padding: 0
              }}>
              <TabBar
                selectedMenu={selectedSidebarMenu}
                selectedTab={selectedTab}
                setSelectedTab={handleTabChange}
                listOfTexts={[]} // 필요한 경우 텍스트 목록 추가
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                handleThemesImageSelect={handleThemesImageSelect}
                describeText={describeText} // 추가된 부분
                setDescribeText={setDescribeText} // 추가된 부분
                dontWantText={dontWantText} // 추가된 부분
                setDontWantText={setDontWantText} // 추가된 부분
                selectedCustomImage={selectedCustomImage}
                setSelectedCustomImage={setSelectedCustomImage}
                positivePrompt={positivePrompt}
                setPositivePrompt={setPositivePrompt}
                negativePrompt={negativePrompt}
                setNegativePrompt={setNegativePrompt}
                onFileListFetched={setFetchedFileList} // TabBar로 파일 목록 업데이트 함수 전달
                setSelectedSubMenu={setSelectedSubMenu} // 추가: 서브메뉴 선택 상태 업데이트 함수 전달
              />
            </Box>

            {/* 오른쪽 이미지 에디터 영역 */}
            <Box
              sx={{
                flex: 1,
                padding: 2,
                overflowY: 'auto',
                backgroundColor: 'background.default',
                color: 'text.primary',
              }}
            >
              {/* selectedSubMenu에 따라 다른 컴포넌트를 렌더링 */}
              {selectedSidebarMenu === 'Tools' ? (
                <ImageGenerator
                  // key={tabKey} // key prop 추가
                  key={`${tabKey}_${selectedSidebarMenu}`} // key prop 수정: selectedSidebarMenu 포함
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  describeText={describeText}
                  dontWantText={dontWantText}
                  selectedCustomImage={selectedCustomImage}
                  positivePrompt={positivePrompt}
                  negativePrompt={negativePrompt}
                  selectedTab={selectedTab}
                  fileList={fetchedFileList}
                />
              ) : selectedSubMenu ? (
                <DynamicComponent 
                  selectedMenu={selectedSidebarMenu} 
                  selectedSubMenu={selectedSubMenu} 
                />
              ) : (
                <ImageEditor
                  key={`${tabKey}_${selectedSidebarMenu}`} // key prop 수정: selectedSidebarMenu 포함
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  describeText={describeText}
                  dontWantText={dontWantText}
                  selectedCustomImage={selectedCustomImage}
                  positivePrompt={positivePrompt}
                  negativePrompt={negativePrompt}
                  selectedTab={selectedTab}
                  fileList={fetchedFileList}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Layout;
