// src/components/TabsContent/SDXLLightning.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';

const SDXLLightning: React.FC = () => {
  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        SDXL Lightning
      </Typography>
      <Typography variant="body1">
        SDXL Lightning 기능 화면입니다. 여기에 해당 기능에 대한 내용을 추가하세요.
      </Typography>
    </Box>
  );
};

export default SDXLLightning;
