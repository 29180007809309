// src/pages/PrivacyPage.tsx
import React from "react";
import { Box, Typography, Link, List, ListItem, ListItemText } from "@mui/material";
import Navbar from "../components/Navbar";

const PrivacyPage: React.FC = () => {
  return (
    <Box>
      <Navbar />
      <Box sx={{ textAlign: "left", padding: "100px 20px", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h2" sx={{ fontSize: "48px", fontWeight: "bold", color: "#333" }}>
          Privacy Policy
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "20px 0", color: "#555" }}>
          At PIXMON, we are committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your information.
        </Typography>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          1. Personal Information Collected
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          Required Information
        </Typography>
        <List sx={{ color: "#555", marginLeft: "20px" }}>
          <ListItem>
            <ListItemText primary="Email address" />
          </ListItem>
        </List>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          Optional Information
        </Typography>
        <List sx={{ color: "#555", marginLeft: "20px" }}>
          <ListItem>
            <ListItemText primary="Name" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Password" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Contact number" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Business registration number (for business members)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Company name" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Website address" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Marketing communication consent" />
          </ListItem>
        </List>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          2. Purpose of Collection and Use of Personal Information
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          Contract Fulfillment for Service Provision
        </Typography>
        <List sx={{ color: "#555", marginLeft: "20px" }}>
          <ListItem>
            <ListItemText primary="Content provision" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Purchase and payment processing" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Shipping or billing address delivery" />
          </ListItem>
        </List>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          Member Management
        </Typography>
        <List sx={{ color: "#555", marginLeft: "20px" }}>
          <ListItem>
            <ListItemText primary="Identity verification for membership services" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Personal identification" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Prevention of unauthorized use by malicious members" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Verification of registration intent" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Age verification" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Complaint handling" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Delivery of notices" />
          </ListItem>
        </List>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          3. Retention and Use Period of Personal Information
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          The company retains and uses user information only during the period of service provision from the date of membership registration.
          When members request account deletion or withdraw consent for the collection and use of personal information,
          or after the purpose of collection and use has been achieved, the relevant information will be destroyed without delay.
        </Typography>
      </Box>

      {/* Footer */}
      <Box
        className="footer"
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#333",
          color: "#fff",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          PIXMON
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
          <Link href="/about" color="inherit" underline="none">
            About Us
          </Link>
          <Link href="/contact" color="inherit" underline="none">
            Contact
          </Link>
          <Link href="/terms" color="inherit" underline="none">
            Terms of Service
          </Link>
          <Link href="/privacy" color="inherit" underline="none">
            Privacy Policy
          </Link>
          <Link href="/copyright" color="inherit" underline="none">
            Copyright
          </Link>
          <Link href="/support" color="inherit" underline="none">
            Support
          </Link>
        </Box>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          &copy; 2025 PIXMON. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPage;
