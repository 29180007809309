// src/pages/SupportPage.tsx
import React from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Link } from "@mui/material";
import Navbar from "../components/Navbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SupportPage: React.FC = () => {
  return (
    <Box>
      <Navbar />
      <Box sx={{ textAlign: "left", padding: "100px 20px", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h2" sx={{ fontSize: "48px", fontWeight: "bold", color: "#333" }}>
          Support
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "20px 0", color: "#555" }}>
          Find answers to common questions below or contact us for further assistance.
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How do I create an account?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can create an account by clicking the "Sign Up" button on the top right corner of the page.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How do I reset my password?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Click on "Forgot Password" on the login page and follow the instructions sent to your email.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Footer */}
      <Box
        className="footer"
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#333",
          color: "#fff",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          PIXMON
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
          <Link href="/about" color="inherit" underline="none">
            About Us
          </Link>
          <Link href="/contact" color="inherit" underline="none">
            Contact
          </Link>
          <Link href="/terms" color="inherit" underline="none">
            Terms of Service
          </Link>
          <Link href="/privacy" color="inherit" underline="none">
            Privacy Policy
          </Link>
          <Link href="/copyright" color="inherit" underline="none">
            Copyright
          </Link>
          <Link href="/support" color="inherit" underline="none">
            Support
          </Link>
        </Box>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          &copy; 2025 PIXMON. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default SupportPage;