// src/pages/TopPage.tsx
import React from "react";
import { Box, Typography, Button, Card, CardContent, Grid, Link } from "@mui/material";
import Navbar from "../components/Navbar"; // Adjust the path based on your project structure

const { REACT_APP_SERVICE_URL } = process.env;

const TopPage: React.FC = () => {
  const redirectToApp = () => {
    window.location.href = REACT_APP_SERVICE_URL + "/tools";
  };

  return (
    <Box>
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <Box
        className="hero"
        sx={{
          textAlign: "center",
          padding: "100px 20px",
          backgroundColor: "#f7f9fc",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "48px", fontWeight: "bold", color: "#333" }}>
          Create Perfect Product Images with AI
        </Typography>
        <Typography
          sx={{ fontSize: "18px", margin: "20px 0", color: "#555" }}
        >
          Generate high-quality product images effortlessly without a photoshoot.
        </Typography>
        <Button
          onClick={redirectToApp}
          sx={{
            backgroundColor: "#ffc100",
            color: "#ffffff",
            padding: "10px 20px",
            borderRadius: "12px",
            fontSize: "1rem",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#e6ac00",
            },
          }}
        >
          Start Generating Images
        </Button>
      </Box>

      {/* Features Section */}
      <Box
        className="features"
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "50px 10%",
          gap: "30px",
          backgroundColor: "#fff",
          flexWrap: "wrap",
        }}
      >
        {[
          {
            imgSrc: REACT_APP_SERVICE_URL + "/images/igor-omilaev-eGGFZ5X2LnA-unsplash.jpg",
            title: "Fast and Simple",
            description: "Create images in 4 seconds",
          },
          {
            imgSrc: REACT_APP_SERVICE_URL + "/images/andrew-abela-7cwl0Hqw7_k-unsplash.jpg",
            title: "High Quality",
            description: "Ready-to-use resolution",
          },
          {
            imgSrc: REACT_APP_SERVICE_URL + "/images/max-K6rEL1VI_J4-unsplash.jpg",
            title: "Wide Variety",
            description: "Over 20 generation options",
          },
        ].map((feature, index) => (
          <Card
            className="feature"
            key={index}
            sx={{
              textAlign: "center",
              flex: "1 1 300px",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={feature.imgSrc}
              alt={feature.title}
              style={{ width: "180px", height: "180px", objectFit: "cover" }}
            />
            <CardContent>
              <Typography variant="h6" sx={{ marginTop: "15px", color: "#333" }}>
                {feature.title}
              </Typography>
              <Typography variant="body2" sx={{ color: "#555" }}>
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Use Cases Section */}
      <Box
        className="use-cases"
        sx={{
          textAlign: "center",
          padding: "80px 20px",
          backgroundColor: "#f7f9fc",
        }}
      >
        <Typography variant="h3" sx={{ fontSize: "36px", fontWeight: "bold", color: "#333" }}>
          Use Cases
        </Typography>
        <Grid container spacing={4} sx={{ marginTop: "20px", padding: "0 10%" }}>
          {[
            {
              title: "E-commerce Product Backgrounds",
              description: "Seamlessly blend product images with various backgrounds to boost sales.",
            },
            {
              title: "Model Outfit Simulation",
              description: "Try different outfits on models and find the perfect style.",
            },
            {
              title: "Product Highlighting",
              description: "Emphasize product features to capture customer attention.",
            },
          ].map((useCase, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="h6" sx={{ marginTop: "15px", color: "#333" }}>
                  {useCase.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#555" }}>
                  {useCase.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Testimonials Section */}
      <Box
        className="testimonials"
        sx={{
          textAlign: "center",
          padding: "80px 20px",
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h3" sx={{ fontSize: "36px", fontWeight: "bold", color: "#333" }}>
          Testimonials
        </Typography>
        <Grid container spacing={4} sx={{ marginTop: "20px", padding: "0 10%" }}>
          {[
            {
              name: "John Doe",
              review: "This service reduced our product image creation time by 80%! Thank you!",
            },
            {
              name: "Jane Smith",
              review: "The outfit simulation is incredibly useful. It helps us visualize styles quickly.",
            },
            {
              name: "Michael Brown",
              review: "Customer response has improved significantly. Our product images look more professional.",
            },
          ].map((testimonial, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="h6" sx={{ marginTop: "15px", color: "#333" }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "#555" }}>
                  {testimonial.review}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Clients Section */}
      <Box
        className="clients"
        sx={{
          textAlign: "center",
          padding: "80px 20px",
          backgroundColor: "#f7f9fc",
        }}
      >
        <Typography variant="h3" sx={{ fontSize: "36px", fontWeight: "bold", color: "#333" }}>
          Our Clients
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "40px", marginTop: "40px" }}>
          {[
            { name: "A Retailer", logo: REACT_APP_SERVICE_URL + "/images/client1.png" },
            { name: "B Fashion Brand", logo: REACT_APP_SERVICE_URL + "/images/client2.png" },
            { name: "C Electronics Company", logo: REACT_APP_SERVICE_URL + "/images/client3.png" },
          ].map((client, index) => (
            <img
              key={index}
              src={client.logo}
              alt={client.name}
              style={{ width: "120px", height: "auto" }}
            />
          ))}
        </Box>
      </Box>

      {/* Footer */}
      <Box
        className="footer"
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#333",
          color: "#fff",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          PIXMON
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
          <Link href="/about" color="inherit" underline="none">
            About Us
          </Link>
          <Link href="/contact" color="inherit" underline="none">
            Contact
          </Link>
          <Link href="/terms" color="inherit" underline="none">
            Terms of Service
          </Link>
          <Link href="/privacy" color="inherit" underline="none">
            Privacy Policy
          </Link>
          <Link href="/copyright" color="inherit" underline="none">
            Copyright
          </Link>
          <Link href="/support" color="inherit" underline="none">
            Support
          </Link>
        </Box>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          &copy; 2025 PIXMON. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default TopPage;