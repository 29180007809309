// src/pages/AboutPage.tsx
import React from "react";
import { Box, Typography, Link, List, ListItem, ListItemText } from "@mui/material";
import Navbar from "../components/Navbar";

const AboutPage: React.FC = () => {
  return (
    <Box>
      <Navbar />
      <Box sx={{ textAlign: "center", padding: "80px 20px" }}>
        <Typography variant="h2" sx={{ fontSize: "48px", fontWeight: "bold", color: "#333" }}>
          About Us
        </Typography>
        <Typography sx={{ fontSize: "20px", margin: "20px 0", color: "#555", maxWidth: "800px", marginX: "auto" }}>
          PIXMON is an AI-powered service that helps you create stunning product images effortlessly.
          Our mission is to empower businesses by providing high-quality, customizable image generation tools.
        </Typography>

        <Box sx={{ marginTop: "40px" }}>
          <Typography variant="h4" sx={{ fontSize: "32px", fontWeight: "bold", color: "#333" }}>
            Key Features
          </Typography>
          <List sx={{ maxWidth: "600px", margin: "20px auto", textAlign: "left" }}>
            <ListItem>
              <ListItemText
                primary="Background Synthesis and Replacement"
                secondary={
                  <Typography component="span" sx={{ color: "#555" }}>
                    - Various background templates<br />
                    - Freedom to change to desired backgrounds<br />
                    - Natural shadow and lighting effects
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Lighting Optimization"
                secondary={
                  <Typography component="span" sx={{ color: "#555" }}>
                    - Lighting effects that highlight product features<br />
                    - Professional studio-grade quality<br />
                    - Real-time preview support
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Virtual Fitting Simulation"
                secondary={
                  <Typography component="span" sx={{ color: "#555" }}>
                    - AI virtual model generation<br />
                    - Various clothing fitting simulations<br />
                    - Customizable body types and poses
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ marginTop: "40px" }}>
          <Typography variant="h4" sx={{ fontSize: "32px", fontWeight: "bold", color: "#333" }}>
            Why Choose Our Service?
          </Typography>
          <List sx={{ maxWidth: "600px", margin: "20px auto", textAlign: "left" }}>
            <ListItem>
              <ListItemText
                primary="Professional photographer-level results"
                primaryTypographyProps={{ color: "#333", fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Time and cost savings"
                primaryTypographyProps={{ color: "#333", fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Easy to use"
                primaryTypographyProps={{ color: "#333", fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Real-time technical support"
                primaryTypographyProps={{ color: "#333", fontWeight: "bold" }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Reasonable pricing"
                primaryTypographyProps={{ color: "#333", fontWeight: "bold" }}
              />
            </ListItem>
          </List>
        </Box>
      </Box>

      {/* Footer */}
      <Box
        className="footer"
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#333",
          color: "#fff",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          PIXMON
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
          <Link href="/about" color="inherit" underline="none">
            About Us
          </Link>
          <Link href="/contact" color="inherit" underline="none">
            Contact
          </Link>
          <Link href="/terms" color="inherit" underline="none">
            Terms of Service
          </Link>
          <Link href="/privacy" color="inherit" underline="none">
            Privacy Policy
          </Link>
          <Link href="/copyright" color="inherit" underline="none">
            Copyright
          </Link>
          <Link href="/support" color="inherit" underline="none">
            Support
          </Link>
        </Box>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          &copy; 2025 PIXMON. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutPage;
