// src/components/Navbar.tsx
import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import pixmonLogo from "../pixmon_logo.png";
import { useUser } from '../contexts/UserContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface NavbarProps {
  onMenuClick?: () => void;
}

const APP_SERVICE_URL = process.env.REACT_APP_SERVICE_URL || "/";

const Navbar: React.FC<NavbarProps> = ({ onMenuClick }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { userProfile, loading, error } = useUser();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (err) {
      console.error("Logout failed:", err);
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const isGalleryPage = location.pathname.includes("gallery");
  const isRootPage = location.pathname === "/";

  // Define navigation links based on the current page
  const getNavLinks = () => {
    if (isRootPage) {
      return (
        <>
          <Button
            color="inherit"
            component={Link}
            to="/tools"
            sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
          >
            Tools
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/gallery"
            sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
          >
            Gallery
          </Button>
        </>
      );
    } else if (isGalleryPage) {
      return (
        <Button
          color="inherit"
          component={Link}
          to="/tools"
          sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
        >
          Tools
        </Button>
      );
    } else {
      return (
        <Button
          color="inherit"
          component={Link}
          to="/gallery"
          sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
        >
          Gallery
        </Button>
      );
    }
  };

// Define the list of navigation links for the Drawer
const drawerList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px', justifyContent: 'space-between' }}>
        <Typography variant="h6">Menu</Typography>
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton component={Link} to="/tools">
            <ListItemText primary="Tools" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/gallery">
            <ListItemText primary="Gallery" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/blog">
            <ListItemText primary="Blog" />
          </ListItemButton>
        </ListItem>
        <ListItem>

            <Tooltip
            title={
                loading
                ? "Loading credit information..."
                : (userProfile?.credit ?? 0) <= 0
                ? "Insufficient credits to generate an image."
                : "Your current credit"
            }
            >
            <span>
                <Button
                color="inherit"
                component={Link}
                to="/pricing"
                sx={{
                    color: "#333333",
                    fontSize: "1.0rem",
                    fontWeight: "600",
                    marginRight: '16px',
                    display: 'flex',
                    alignItems: 'center',
                }}
                disabled={loading || !(userProfile?.credit && userProfile.credit > 0)}
                >
                Credit(
                <Typography component="span" sx={{ color: "#ffffff", marginLeft: '4px', marginRight: '4px' }}>
                    {loading ? "..." : currentUser ? userProfile?.credit : ""}
                </Typography>
                )
                </Button>
            </span>
            </Tooltip>


        </ListItem>
      </List>
      <Divider />
      <List>
        {currentUser ? (
          <>
            <ListItem>
              <Typography
                variant="body1"
                sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
              >
                {loading ? "Loading..." : error ? error : (userProfile?.nickname || userProfile?.email)}
              </Typography>
            </ListItem>
            <ListItem>
              <Button
                color="inherit"
                onClick={handleLogout}
                sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600", width: '100%' }}
              >
                Logout
              </Button>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem>
              <Button
                color="inherit"
                component={Link}
                to="/login"
                sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600", width: '100%' }}
              >
                Login
              </Button>
            </ListItem>
            <ListItem>
              <Button
                color="inherit"
                component={Link}
                to="/signup"
                sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600", width: '100%' }}
              >
                Signup
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );
  

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: "#ffc100" }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer(true)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Link to={APP_SERVICE_URL} style={{ color: 'inherit', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <img
              src={pixmonLogo}
              alt="Pixmon Logo"
              style={{ height: "40px", marginRight: "16px" }}
            />
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          {/* Desktop Navigation Links */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {getNavLinks()}
              <Button
                color="inherit"
                component={Link}
                to="/blog"
                sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
              >
                Blog
              </Button>
              <Tooltip title={(userProfile?.credit ?? 0) <= 0 ? "Insufficient credits to generate an image." : "Your current credit"}>
                <span>
                  <Button
                    color="inherit"
                    component={Link}
                    to="/pricing"
                    sx={{
                      color: "#333333",
                      fontSize: "1.0rem",
                      fontWeight: "600",
                      marginRight: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    disabled={!(userProfile?.credit && userProfile.credit > 0)}
                  >
                    Credit(
                    <Typography component="span" sx={{ color: "#ffffff", marginLeft: '4px', marginRight: '4px' }}>
                      {currentUser ? (userProfile?.credit ?? 0) : ""}
                    </Typography>
                    )
                  </Button>
                </span>
              </Tooltip>

              {currentUser ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="body1"
                    sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
                  >
                    {loading ? "Loading..." : error ? error : (userProfile?.nickname || userProfile?.email)}
                  </Typography>
                  <Button
                    color="inherit"
                    onClick={handleLogout}
                    sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
                  >
                    Logout
                  </Button>
                </Stack>
              ) : (
                <>
                  <Button
                    color="inherit"
                    component={Link}
                    to="/login"
                    sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
                  >
                    Login
                  </Button>
                  <Button
                    color="inherit"
                    component={Link}
                    to="/signup"
                    sx={{ color: "#333333", fontSize: "1.0rem", fontWeight: "600" }}
                  >
                    Signup
                  </Button>
                </>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawerList()}
      </Drawer>

      {/* To push the content below the AppBar */}
      <Toolbar />
    </>
  );
};

export default Navbar;
