// src/components/ImageEditor.tsx
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import {
  Box,
  Typography,
  Button,
  styled,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
  Fade,
  Checkbox,
  IconButton,
  ImageList,
  ImageListItem,
  Card,
  CardMedia,
  Modal,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Tooltip
} from '@mui/material';
import { useDropzone, FileRejection } from 'react-dropzone';
import SliderBar from './SliderBar';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CropIcon from '@mui/icons-material/Crop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite'; // 좋아요 아이콘 추가
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'; // 좋아요 아이콘 추가
import ShareIcon from '@mui/icons-material/Share'; // 공유 아이콘 추가
import { SelectedImage } from '../types';
import { saveAs } from 'file-saver';
import ImageEditorLib from '@toast-ui/react-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import axiosInstance from '../api/axios';
import { PromptContext } from '../contexts/PromptContext'; // PromptContext 임포트
import { Prompt } from '../types/Prompt';
import { useUser } from '../contexts/UserContext'; // Import UserContext

// 타입 정의
interface ImageData {
  id: string;
  originalFile: File;
  originalUrl: string;
  processedUrl?: string;
  error?: string;
  isProcessing: boolean;
  isSelected?: boolean;
}

interface ComposedImageData {
  id: string;
  url: string;
  originalUrl: string;
  isSelected: boolean;
  showOverlay?: boolean;
  liked?: boolean; // 좋아요 상태 추가
}

// Props 인터페이스 정의
interface ImageEditorProps {
  selectedImage: SelectedImage | null;
  setSelectedImage: (image: SelectedImage | null) => void;
  describeText: string;
  dontWantText: string;
  selectedCustomImage: string | null;
  positivePrompt: string;
  negativePrompt: string;
  selectedTab: string;
  fileList?: BgFile[]; // 새로운 prop 추가
}

interface BgFile {
  file_hash: string;
  uuid_folder: string;
  filename: string;
  url: string;
}

// 스타일 정의
const ViewerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '600px',
  aspectRatio: '1 / 1',
  backgroundColor: '#fafafa',
  backgroundImage: `linear-gradient(45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                    linear-gradient(-45deg, ${theme.palette.grey[300]} 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, ${theme.palette.grey[300]} 75%),
                    linear-gradient(-45deg, transparent 75%, ${theme.palette.grey[300]} 75%)`,
  backgroundSize: '20px 20px',
  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
  border: '2px dashed #FFEB3B',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[6],
  overflow: 'auto',
  margin: '0 auto',
  cursor: 'pointer',
}));

const StyledImage = styled('img')<{ scale: number }>(({ scale }) => ({
  width: `${scale}%`,
  height: `${scale}%`,
  objectFit: scale > 100 ? 'contain' : 'cover',
  transition: 'opacity 0.3s',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const UploadButtonOverlay = styled(Button)(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 3,
}));

const EditingResultsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

interface ImageBoxProps {
  isSelected: boolean;
}

const ImageBox = styled(Box)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  position: 'relative',
  width: '150px',
  height: '150px',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: theme.shadows[2],
  backgroundColor: '#ffffff',
  margin: theme.spacing(0.5),
  transition: 'transform 0.3s, box-shadow 0.3s, opacity 0.3s',
  opacity: isSelected ? 0.7 : 1,
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
  '&:hover .overlay': {
    display: 'flex',
  },
  ...(isSelected && {
    '& .overlay': {
      display: 'flex',
    },
  }),
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  display: 'none',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5),
  boxSizing: 'border-box',
}));

const ButtonGroupContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(1),
}));

const FullscreenOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  overflowY: 'auto',
}));

const OverlayContent = styled(Box)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  position: 'relative',
  width: isMobile ? '90%' : 'calc(100% - 200px)',
  height: isMobile ? '90%' : '80%',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    height: '90%',
    flexDirection: 'column',
  },
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  [theme.breakpoints.down('sm')]: {
    top: '50%',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

// TUI Image Editor 모달 컴포넌트
const ImageEditorComponent: React.FC<{
  src: string;
  onSave: (editedImage: string) => void;
  onClose: () => void;
  initialMenu: string;
}> = ({ src, onSave, onClose, initialMenu }) => {
  const editorRef = useRef<any>(null);
  
  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current.getInstance();
      
      // 초기 이미지를 로드하고, Load 버튼을 표시하지 않도록 설정
      editorInstance.loadImageFromURL(src, '이미지').then(() => {
        editorInstance.clearUndoStack(); // Undo 스택 초기화
      });
    }
  }, [src]);

  const handleSave = () => {
    if (editorRef.current) {
      const instance = editorRef.current.getInstance();
      // mask 메뉴 사용 시 필요한 객체가 있는지 확인합니다.
      if (instance.hasFilter('mask')) {
        // 마스크 객체 추가 예시
        instance.addImageObject('mask-image-url', {
          left: 100,
          top: 100,
          angle: 0,
          width: 200,
          height: 200,
        });
      }
      const dataURL = instance.toDataURL();
      onSave(dataURL);
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Photo Edit</DialogTitle>
      <DialogContent>
        <ImageEditorLib
          ref={editorRef}
          includeUI={{
            loadImage: {
              path: src,
              name: '이미지',
            },
            theme: {},
            menu: ['shape', 'filter', 'text', 'icon', 'mask', 'crop', 'flip', 'rotate', 'draw'],
            initMenu: initialMenu,
            uiSize: {
              width: '100%',
              height: '700px',
            },
            menuBarPosition: 'bottom',
          }}
          cssMaxHeight={700}
          cssMaxWidth={700}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={false}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 2 }}>
            저장
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            취소
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// 헬퍼 함수: 쿠키에서 특정 이름의 값을 가져오는 함수
const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};

const ImageEditorComponentWrapper: React.FC<ImageEditorProps> = ({
  selectedImage,
  setSelectedImage,
  describeText,
  dontWantText,
  selectedCustomImage,
  positivePrompt,
  negativePrompt,
  selectedTab,
  fileList, // 새로운 prop 사용
}) => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [quality, setQuality] = useState<string>('High Quality & Low Speed');
  const [scale, setScale] = useState<number>(100); // 초기 scale을 100%로 설정
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [composeProcessedImages, setComposeProcessedImages] = useState<ComposedImageData[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { prompts, setPrompts, selectedPrompt, setSelectedPrompt } = useContext(PromptContext); // Access selectedPrompt from context
  const { userProfile, loading: userLoading, error: userError, updateCredit } = useUser(); // Consume UserContext

  const itemSize = isMobile ? 150 : 200;
  const [editInitialMenu, setEditInitialMenu] = useState<string>(''); // 초기 메뉴 설정

  // ============ 공유 기능을 위한 상태 ============
  const [openShareDialog, setOpenShareDialog] = useState(false);        // 다이얼로그 열림/닫힘
  const [shareTargetImage, setShareTargetImage] = useState<ComposedImageData | null>(null); 
  const [shareDescription, setShareDescription] = useState('');
  const [shareTags, setShareTags] = useState('');

  // 전체 오버레이 관리
  const [fullscreenOverlay, setFullscreenOverlay] = useState<{
    open: boolean;
    currentImageIndex: number;
  }>({
    open: false,
    currentImageIndex: 0,
  });

  // 편집 모달 상태
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [imageToEdit, setImageToEdit] = useState<ImageData | null>(null);

  // 이미지 정사각형으로 자르고 512x512로 리사이즈하는 함수
  const cropAndResizeImageToSquare = async (file: File): Promise<File> => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const sideLength = Math.min(image.width, image.height);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // 정사각형으로 크롭하기 위한 캔버스 설정
    if (ctx) {
      // 크롭용 임시 캔버스
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = sideLength;
      tempCanvas.height = sideLength;
      const tempCtx = tempCanvas.getContext('2d');

      if (tempCtx) {
        const xOffset = (image.width - sideLength) / 2;
        const yOffset = (image.height - sideLength) / 2;
        tempCtx.drawImage(image, xOffset, yOffset, sideLength, sideLength, 0, 0, sideLength, sideLength);
        
        // 512x512 크기로 리사이즈
        canvas.width = 512;
        canvas.height = 512;
        ctx.drawImage(tempCanvas, 0, 0, sideLength, sideLength, 0, 0, 512, 512);
      }
    }

    const resizedBlob = await new Promise<Blob | null>((resolve) =>
      canvas.toBlob((blob) => resolve(blob), 'image/png')
    );

    if (resizedBlob) {
      return new File([resizedBlob], file.name, { type: 'image/png' });
    } else {
      throw new Error('Image resizing failed');
    }
  };

  // 쿠키 관리 헬퍼 함수
  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  };

  const setCookie = (name: string, value: string, days: number = 1) => {
    const expires = new Date();
    const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;

    if (!serviceDomain) {
      console.error('환경변수 REACT_APP_SERVICE_DOMAIN이 설정되지 않았습니다.');
      return;
    }
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};Domain=${serviceDomain}; path=/;Secure;SameSite=Strict`;
    // document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};Domain=.pixmon.ai; path=/;Secure;SameSite=Strict`;
  };
  
// Inside ImageEditorComponentWrapper

const onDrop = useCallback(
  async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const imageFiles = acceptedFiles.filter((file) => file.type.startsWith('image/'));
    
    try {
      // **Prompts 상태 변수 초기화**
      setPrompts([]); // 서버 요청 전에 기존 prompts 상태 초기화

      // 이미지를 자르고 설정하는 비동기 처리
      const newImages: ImageData[] = await Promise.all(
        imageFiles.map(async (file) => {
          const croppedFile = await cropAndResizeImageToSquare(file);
          return {
            id: uuidv4(),
            originalFile: croppedFile,
            originalUrl: URL.createObjectURL(croppedFile),
            isProcessing: true,
            isSelected: false,
            showOverlay: false, // 추가: 초기 상태 설정
          };
        })
      );

      // 새 이미지를 설정
      setImages(newImages);

      // 업로드한 이미지를 선택된 이미지로 설정
      if (newImages.length > 0) {
        const firstImage = newImages[0];
        const selectedImageData: SelectedImage = {
          id: firstImage.id,
          url: firstImage.originalUrl,
          source: 'upload',
          originalUrl: firstImage.originalUrl, // originalUrl 포함
        };
        setSelectedImage(selectedImageData);
      }

      // 모든 이미지에 대해 handleRemoveBackground를 await하여 처리 완료를 보장
      await Promise.all(newImages.map((image) => handleRemoveBackground(image.id, image.originalFile)));

      // **ImageList 초기화 및 새로운 이미지 목록 불러오기**
      // Removed initial setComposeProcessedImages([]) here

      const viewerbox_uuid = getCookie('viewerbox_uuid');
      console.log('##### VIEWERBOX_UUID = [%s]', viewerbox_uuid)
      if (viewerbox_uuid) {
        try {
          const response = await axiosInstance.post(
            '/bgfile_gi_list',
            { filename: viewerbox_uuid },
            { withCredentials: true }
          );

          console.log('bgfile_gi_list 응답:', response.data); // 디버깅용 로그 추가

          // 서버에서 받은 이미지 목록이 BgFile[] 형식이라고 가정
          const bgFiles: BgFile[] = response.data.files;

          if (!Array.isArray(bgFiles)) {
            throw new TypeError('서버 응답 형식이 올바르지 않습니다. "files" 배열을 기대합니다.');
          }

          if (bgFiles.length === 0) {
            // If no files are returned, clear the ImageList
            setComposeProcessedImages([]);
            console.log('No files received from bgfile_gi_list. ImageList has been cleared.');
          } else {
            // ImageList에 표시할 이미지로 변환
            const newComposeImages: ComposedImageData[] = bgFiles.map((file) => ({
              id: uuidv4(), // 고유한 id 할당
              url: file.url, // 서버의 URL
              originalUrl: file.url, // 원본 URL도 저장
              isSelected: false,
              showOverlay: false,
              liked: false, // 초기 좋아요 상태 설정
            }));

            setComposeProcessedImages(newComposeImages);
            console.log('ComposeProcessedImages has been updated with new images.');
          }
        } catch (err) {
          console.error('이미지 목록을 가져오는 중 오류 발생:', err);
          // On error, ensure ImageList is cleared
          setComposeProcessedImages([]);
          console.log('Error occurred while fetching bgfile_gi_list. ImageList has been cleared.');
          // Optionally, you can uncomment the toast notification
          // toast.error('이미지 목록을 가져오는 데 실패했습니다.');
        }
      } else {
        // If viewerbox_uuid is not present, ensure ImageList is cleared
        setComposeProcessedImages([]);
        console.log('viewerbox_uuid not found. ImageList has been cleared.');
      }

      // **save_folder_id를 current_folder_id로 설정하고 쿠키에 저장**
      const currentFolderId = getCookie('current_folder_id') || 'root';
      const existingFolder = getCookie('existing_folder');
      
      if (existingFolder !== null)
        setCookie('save_folder_id', existingFolder, 1);
      else
        setCookie('save_folder_id', currentFolderId, 1); // save_folder_id 쿠키 설정

      // **프롬프트 저장 및 콘솔 출력 추가**
      const currentPrompts = prompts; // 기존 PromptContext의 상태를 가져옴
      console.log('Current Prompts:', currentPrompts);
      const saveFolderId = getCookie('save_folder_id')
      console.log("##### save_folder_id : ", saveFolderId)

      // Handle rejected files (if any)
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((e) => {
          toast.error(`파일 업로드 실패: ${file.name} - ${e.message}`);
        });
      });
    } catch (err) {
      console.error('이미지 업로드 및 처리 중 오류:', err);
      toast.error('이미지 업로드 및 처리 중 오류가 발생했습니다.');
      // Optionally, clear the ImageList on major errors
      setComposeProcessedImages([]);
      console.log('Major error occurred. ImageList has been cleared.');
    }
  },
  [setSelectedImage, prompts, setPrompts] // Added setPrompts as dependency
);


  const { getRootProps, getInputProps, isDragActive, open: openDropzone } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

    // 탭 변경 시 상태 초기화
    useEffect(() => {
      setImages([]);
      setComposeProcessedImages([]);
      setQuality('High Quality & Low Speed');
      setScale(100);
      setIsLoading(false);
      setError('');
      setFullscreenOverlay({
        open: false,
        currentImageIndex: 0,
      });
      setImageToEdit(null);
      setIsEditModalOpen(false);
      // 필요한 다른 상태 초기화
    }, [selectedTab]);

  // fileList가 변경될 때 composeProcessedImages 업데이트
  useEffect(() => {
    if (fileList && fileList.length > 0) {
      const mappedImages: ComposedImageData[] = fileList.map((file) => ({
        id: uuidv4(), // 고유한 id 할당
        url: file.url, // 서버의 URL
        originalUrl: file.url, // 원본 URL도 저장
        isSelected: false,
        showOverlay: false,
        liked: false, // 초기 좋아요 상태 설정
      }));
      setComposeProcessedImages(mappedImages);
    } else {
      setComposeProcessedImages([]); // fileList가 비어있을 경우 초기화
    }
  }, [fileList]);

  useEffect(() => {
    // 컴포넌트 언마운트 시 URL 해제
    return () => {
      images.forEach((image) => {
        URL.revokeObjectURL(image.originalUrl);
        if (image.processedUrl) {
          URL.revokeObjectURL(image.processedUrl);
        }
      });
    };
  }, [images]);

  const deleteImageOnServer = async (imageUrl: string) => {
    try {
      await axiosInstance.post(
        '/delete_gi_file',
        { url: imageUrl },
        { withCredentials: true }
      );
      console.log(`Successfully deleted image on server: ${imageUrl}`);
    } catch (error) {
      console.error(`Failed to delete image on server: ${imageUrl}`, error);
      throw error;
    }
  };

  // ViewerBox에 표시할 이미지 URL 결정 함수
  const getViewerBoxImageUrl = () => {
    if (selectedImage) {
      if (selectedImage.source === 'upload') {
        const imageData = images.find(img => img.id === selectedImage.id);
        return imageData?.processedUrl || selectedImage.url;
      } else {
        // AssetsTab에서 선택한 이미지 또는 ImageList의 합성 이미지
        return selectedImage.url;
      }
    } else if (composeProcessedImages.length > 0) {
      // 합성된 이미지 중 첫 번째 이미지를 표시
      return composeProcessedImages[0].url;
    } else if (images.length > 0) {
      // 업로드된 이미지 중 처리된 이미지
      const processedImage = images.find((img) => img.processedUrl && !img.isProcessing);
      return processedImage ? processedImage.processedUrl : images[0].originalUrl;
    }
    return null;
  };

  // ImageList에서 이미지 클릭 시 ViewerBox가 업데이트되지 않도록 수정
  const handleImageListClick = (image: ComposedImageData) => {
    openFullscreenOverlay(image.id); // 오버레이 열기만 수행
  };

  const handleScaleChange = (newScale: number) => {
    setScale(newScale);
  };

  // 배경 제거 요청 핸들러 (수정됨)
  const handleRemoveBackground = async (id: string, file: File) => {
    // SHA-256 해시를 계산하는 함수
    const computeSHA256 = async (file: File): Promise<string> => {
      const arrayBuffer = await file.arrayBuffer();
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    };
    const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;

    if (!serviceDomain) {
      console.error('환경변수 REACT_APP_SERVICE_DOMAIN이 설정되지 않았습니다.');
      return;
    }

    setCookie('viewerbox_uuid', "", 1);
    try {
      // 1. 파일의 SHA-256 해시 계산
      const fileHash = await computeSHA256(file);
      document.cookie = `file_hash=${fileHash}; Domain=${serviceDomain}; path=/; Secure;SameSite=None`;

      // 2. FormData에 파일 추가
      const formData = new FormData();
      formData.append('file', file);

      // 3. 파일 업로드
      const response = await axiosInstance.post(
        '/remove-background',
        formData,
        {
          responseType: 'json',  // 'json'으로 설정
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          // 크로스 도메인 요청 시 쿠키를 포함하도록 설정
          withCredentials: true,
        }
      );

      console.log("### remove-background 응답:", response.data); // 응답 로깅 추가

      // 4. 응답 처리 (이미지 표시 등)
      const { image_url, background_prompts } = response.data;

      if (!image_url) {
        throw new Error("Image URL is missing in the response.");
      }

      const processedImageURL = image_url;

      console.log('background_prompts:', background_prompts);

      // 5. 상태 업데이트
      setImages((prevImages) =>
        prevImages.map((img) =>
          img.id === id
            ? { 
                ...img, 
                processedUrl: processedImageURL, 
                backgroundPrompts: background_prompts, 
                isProcessing: false 
              }
            : img
        )
      );

      // **프롬프트를 PromptContext에 설정**
      if (background_prompts.length > 0) {
        setPrompts(background_prompts);
      }
      console.log('Updated Prompts:', background_prompts);


    } catch (err: any) {
      console.error('배경 제거 에러:', err);
      toast.error('Image processing failed. Displaying the original image.');
      setImages((prevImages) =>
        prevImages.map((img) =>
          img.id === id 
            ? { 
                ...img, 
                error: 'Background removal failed', 
                isProcessing: false 
              } 
            : img
        )
      );
    }
  };

  // Compose Background 핸들러 remains unchanged except handleComposeBackground
  const handleComposeBackground = async () => {
    const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;

    if (!serviceDomain) {
      console.error('환경변수 REACT_APP_SERVICE_DOMAIN이 설정되지 않았습니다.');
      return;
    }
    if (!selectedImage) {
      toast.error('Please upload or select a main image.');
      return;
    }

    // Check if user has sufficient credit
    if (!userProfile || userProfile.credit <= 0) {
      toast.error('You do not have enough credits. Please purchase credits and use them.');
      return;
    }

    // Generate UUID before making the request
    const newImageId = uuidv4();
    // Set file_id cookie
    document.cookie = `client_id=${newImageId}; Domain=${serviceDomain}; path=/; Secure;SameSite=Strict`;

    // Retrieve the selected prompt from context
    let effectivePositivePrompt = positivePrompt; // string
    if (selectedPrompt) {
      effectivePositivePrompt = selectedPrompt.prompt; // string
    }

    if (!negativePrompt.trim()) {  
      negativePrompt = "low quality, bad quality, blurry, low resolution, pixelated, poor details, oversaturated, overexposed, underexposed, bad anatomy, bad proportions, distorted, deformed"
    }

    // 모든 업로드된 이미지가 처리되었는지 확인
    const isAnyImageProcessing = images.some((img) => img.isProcessing);
    if (isAnyImageProcessing) {
      toast.error('Please wait until image processing is complete.');
      return;
    }
  
    setIsLoading(true);
    setError('');

    const formData = new FormData();
    try {
      let mainFileURL = selectedImage.url;

      // 선택된 이미지가 업로드된 이미지인 경우, processedUrl 사용
      if (selectedImage.source === 'upload') {
        const mainImageData = images.find(img => img.id === selectedImage.id);
        if (mainImageData && mainImageData.processedUrl) {
          mainFileURL = mainImageData.processedUrl;
        }
      }

      // 선택된 이미지가 ImageList나 AssetsTab의 이미지인 경우, composeProcessedImages에서 찾기
      else if (selectedImage.source === 'imagelist' || selectedImage.source === 'assets') {
        const mainImageData = composeProcessedImages.find(img => img.id === selectedImage.id);
        if (mainImageData) {
          mainFileURL = mainImageData.url;
        }
      }

      // 프록시 서버를 통해 이미지 가져오기
      const proxyResponse = await axiosInstance.post(
        '/proxy-fetch',
        { url: mainFileURL },
        { responseType: 'blob', withCredentials: true }
      );

      const mainFileBlob = new Blob([proxyResponse.data], { type: 'image/png' });
      const mainFile = new File([mainFileBlob], 'main.png', { type: 'image/png' });
      formData.append('main_file', mainFile);

      // Use the effectivePositivePrompt as positive_prompt
      formData.append('positive_prompt', effectivePositivePrompt);

      // Assuming negativePrompt is still provided via props or another source
      formData.append('negative_prompt', negativePrompt);
      formData.append('quality_option', quality);

      let apiUrl = '/composite-background';

      if (!selectedCustomImage) {
        // 배경 이미지가 없는 경우, composite-random 엔드포인트로 전송
        apiUrl = '/composite-random';
      } else {
        // 배경 이미지가 있는 경우, background_file을 추가하여 composite-background 엔드포인트로 전송
        const bgImageResponse = await fetch(selectedCustomImage);
        const bgImageBlob = await bgImageResponse.blob();
        const bgFile = new File([bgImageBlob], 'background.png', { type: 'image/png' });
        formData.append('background_file', bgFile);
      }

      // Set the cookie based on the image source
      if (selectedImage) {
        if (selectedImage.source === 'assets') {
          // For assets, set "PIXMON:{filename}"
          const filename = selectedImage.url.split('/').pop() || 'background.png';
          document.cookie = `file_hash=PIXMON:${filename}; Domain=${serviceDomain}; path=/; Secure;SameSite=Strict`;
        } else {
          // For uploads, the existing handleRemoveBackground sets "file_hash" with hash
          // Ensure that it's already set before calling handleComposeBackground
          // If not, you may need to compute and set it here as well
        }
      }

      const response = await axiosInstance.post(apiUrl, formData, { responseType: 'blob', withCredentials: true });

      const contentType = response.headers['content-type'];
      if (contentType.startsWith('image/')) {
        const processedImageBlob = new Blob([response.data], { type: 'image/png' });
        const processedImageURL = URL.createObjectURL(processedImageBlob);
        const newComposedImage: ComposedImageData = {
          id: newImageId,
          url: processedImageURL,
          originalUrl: mainFileURL, // originalUrl 추가
          isSelected: false,
          showOverlay: false, // 추가: 초기 상태 설정
          liked: false, // 초기 좋아요 상태 설정
        };
        // 사용 후 해제
        setTimeout(() => {
          URL.revokeObjectURL(processedImageURL);
        }, 0);

        // setComposeProcessedImages((prevImages) => [
        //   newComposedImage,
        //   ...prevImages,
        // ]);

        setComposeProcessedImages((prevImages) => {
          const updatedImages = [newComposedImage, ...prevImages];
          
          // Process URLs before logging
          const processedImages = updatedImages.map(img => {
            if (img.url.startsWith('blob:')) {
              // Extract the last directory and filename from originalUrl
              const urlParts = img.originalUrl.split('/');
              const lastDir = urlParts[urlParts.length - 2];
              
              // Replace the filename with the image id
              const newOriginalUrl = img.originalUrl.replace(
                `${lastDir}/${urlParts[urlParts.length - 1]}`,
                `${lastDir}/${img.id}.png`
              );
              
              return {
                ...img,
                originalUrl: newOriginalUrl
              };
            }
            return img;
          });

          // Log the updated ImageList information
          console.log('=== ImageList Rendering Information ===');
          console.log('Total number of images:', processedImages.length);
          console.log('Latest composed image:', {
            id: processedImages[0].id,
            url: processedImages[0].url,
            originalUrl: processedImages[0].originalUrl,
            isSelected: processedImages[0].isSelected,
            liked: processedImages[0].liked
          });
          console.log('All composed images:', processedImages.map(img => ({
            id: img.id,
            url: img.url,
            originalUrl: img.originalUrl,
            isSelected: img.isSelected,
            liked: img.liked
          })));
          
          return processedImages;
        });

        // **Deduct 1 credit after successful image creation**
        await updateCredit(1); // Deduct 1 credit (assuming updateCredit deducts the amount)

      } else {
        setError('서버로부터 예상치 못한 응답을 받았습니다.');
      }
    } catch (err) {
      console.error('배경 합성 에러:', err);
      setError('이미지 합성을 실패했습니다. 다시 시도해주세요.');
    } finally {
      setIsLoading(false);
    }
  };

  // 편집 결과 이미지 (배경 제거된 모든 이미지)
  const processedImages = images.filter(
    (img) => img.processedUrl && !img.isProcessing
  );

  // 선택된 이미지들의 ID
  const selectedImageIds = composeProcessedImages
    .filter((img) => img.isSelected)
    .map((img) => img.id);

  const handleMouseEnter = (id: string) => {
    setComposeProcessedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, showOverlay: true } : img
      )
    );
    console.log('Mouse Enter:', id);
    console.log('Images after Mouse Enter:', composeProcessedImages);
  };
  
  const handleMouseLeave = (id: string) => {
    setComposeProcessedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id && !img.isSelected ? { ...img, showOverlay: false } : img
      )
    );
    console.log('Mouse Leave:', id);
    console.log('Images after Mouse Leave:', composeProcessedImages);
  };
  
  const handleSelectComposedImage = (id: string) => {
    setComposeProcessedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id
          ? { ...img, isSelected: !img.isSelected }
          : img
      )
    );
    console.log('Select Image:', id);
    console.log('Images after Select:', composeProcessedImages);
  };

  // Delete 핸들러 수정 (originalUrl 사용)
  const handleDeleteSelected = async () => {
    if (selectedImageIds.length === 0) {
      toast.warning('삭제할 이미지를 선택해주세요.');
      return;
    }
  
    // 삭제할 이미지들의 originalUrl 가져오기
    const imagesToDelete = composeProcessedImages.filter(img => img.isSelected);

    try {
      // 서버에 삭제 요청 보내기
      await Promise.all(imagesToDelete.map(async (img) => {
        await deleteImageOnServer(img.originalUrl); // originalUrl을 전송
      }));

      // 삭제 후 상태 업데이트
      setComposeProcessedImages(prevImages => prevImages.filter(img => !img.isSelected));
      toast.success('선택된 이미지가 삭제되었습니다.');
    } catch (error) {
      toast.error('선택된 이미지를 삭제하는 데 실패했습니다.');
    }
  };

  // Download 핸들러 수정 필요 없음
  const handleDownloadSelected = () => {
    if (selectedImageIds.length === 0) {
      toast.warning('다운로드할 이미지를 선택해주세요.');
      return;
    }

    composeProcessedImages
      .filter((img) => img.isSelected)
      .forEach((img) => {
        const link = document.createElement('a');
        link.href = img.url;
        link.download = `composed_image_${img.id}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    toast.success('선택된 이미지가 다운로드되었습니다.');
  };

  // 전체 화면 모드에서 사용되는 Delete 및 Download 핸들러
  const handleFullscreenDeleteSelected = async () => {
    const currentIndex = fullscreenOverlay.currentImageIndex;
    if (currentIndex === -1) {
      toast.warning('삭제할 이미지를 선택해주세요.');
      return;
    }
  
    const selectedImage = composeProcessedImages[currentIndex];
    if (!selectedImage) {
      toast.error('삭제할 이미지가 없습니다.');
      return;
    }
  
    try {
      // 서버에 삭제 요청 보내기
      await deleteImageOnServer(selectedImage.originalUrl); // originalUrl을 전송

      // 상태 업데이트
      const updatedImages = [...composeProcessedImages];
      updatedImages.splice(currentIndex, 1);
      setComposeProcessedImages(updatedImages);

      // 전체 오버레이 상태 업데이트
      if (updatedImages.length > 0) {
        const nextIndex = currentIndex < updatedImages.length ? currentIndex : updatedImages.length - 1;
        setFullscreenOverlay({
          open: true,
          currentImageIndex: nextIndex,
        });
      } else {
        setFullscreenOverlay({
          open: false,
          currentImageIndex: -1,
        });
      }

      toast.success('선택된 이미지가 삭제되었습니다.');
    } catch (error) {
      toast.error('선택된 이미지를 삭제하는 데 실패했습니다.');
    }
  };

  const handleFullscreenDownloadSelected = () => {
    if (fullscreenOverlay.currentImageIndex === -1) {
      toast.warning('다운로드할 이미지를 선택해주세요.');
      return;
    }

    const selectedImage = composeProcessedImages[fullscreenOverlay.currentImageIndex];
    if (selectedImage) {
      saveAs(selectedImage.url, `composed_image_${selectedImage.id}.png`);
      toast.success('선택된 이미지가 다운로드되었습니다.');
    }
  };

  const handleCreateImageClick = () => {
    openDropzone();
  };

  const anySelected = composeProcessedImages.some((img) => img.isSelected);

  // Edit 버튼 클릭 시 호출되는 핸들러
  const handleEdit = () => {
    const currentImage = composeProcessedImages[fullscreenOverlay.currentImageIndex];
    if (currentImage) {
      setImageToEdit({
        id: currentImage.id,
        originalFile: new File([], ''), // 실제 파일을 사용하지 않는 경우 빈 파일 생성
        originalUrl: currentImage.originalUrl, // originalUrl 유지
        processedUrl: currentImage.url, // 편집된 이미지 URL
        isProcessing: false,
        isSelected: false,
      });
      setIsEditModalOpen(true);
      setEditInitialMenu(''); // mask 메뉴로 설정
    } else {
      toast.warning('편집할 이미지를 선택해주세요.');
    }
  };

  // 편집 완료 후 호출되는 핸들러
  const handleSaveEditedImage = (editedImageDataURL: string) => {
    if (imageToEdit) {
      // 기존 이미지 URL 해제
      URL.revokeObjectURL(composeProcessedImages[fullscreenOverlay.currentImageIndex].url);

      // 새로운 Blob 생성 및 URL 생성
      fetch(editedImageDataURL)
        .then(res => res.blob())
        .then(blob => {
          const editedURL = URL.createObjectURL(blob);

          // 전체 오버레이 모달의 이미지를 업데이트 (originalUrl 유지)
          setComposeProcessedImages(prevImages =>
            prevImages.map((img, index) =>
              index === fullscreenOverlay.currentImageIndex
                ? { ...img, url: editedURL } // originalUrl은 변경하지 않음
                : img
            )
          );

          // 선택된 이미지를 ViewerBox에 업데이트 (selectedImage가 설정되어 있을 경우)
          if (selectedImage && selectedImage.id === imageToEdit.id) {
            setSelectedImage({
              ...selectedImage,
              url: editedURL,
            });
          }

          toast.success('이미지가 성공적으로 편집되었습니다.');
        })
        .catch(err => {
          console.error('편집된 이미지 저장 오류:', err);
          toast.error('편집된 이미지를 저장하는 데 실패했습니다.');
        });

      setIsEditModalOpen(false);
      setImageToEdit(null);
    }
  };

  // 편집 모달 닫기 핸들러
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setImageToEdit(null);
  };

  // 전체 오버레이 열기
  const openFullscreenOverlay = (id: string) => {
    const index = composeProcessedImages.findIndex((img) => img.id === id);
    if (index !== -1) {
      setFullscreenOverlay({
        open: true,
        currentImageIndex: index,
      });
    }
  };

  // 전체 오버레이 닫기
  const closeFullscreenOverlay = () => {
    setFullscreenOverlay({
      open: false,
      currentImageIndex: fullscreenOverlay.currentImageIndex,
    });
  };

  // 좌측 화살표 클릭 핸들러
  const handlePrevImage = () => {
    setFullscreenOverlay((prev) => ({
      ...prev,
      currentImageIndex:
        prev.currentImageIndex === 0
          ? composeProcessedImages.length - 1
          : prev.currentImageIndex - 1,
    }));
  };

  // 우측 화살표 클릭 핸들러
  const handleNextImage = () => {
    setFullscreenOverlay((prev) => ({
      ...prev,
      currentImageIndex:
        prev.currentImageIndex === composeProcessedImages.length - 1
          ? 0
          : prev.currentImageIndex + 1,
    }));
  };

  // Replace Product 핸들러 수정 (기존 핸들러 유지)
  const handleReplaceProduct = () => {
    // Replace Product 기능 구현 예정
    toast.info('Replace Product 버튼이 클릭되었습니다.');
  };

  // Resize 핸들러
  const handleResize = () => {
    const currentImage = composeProcessedImages[fullscreenOverlay.currentImageIndex];
    if (currentImage) {
      setImageToEdit({
        id: currentImage.id,
        originalFile: new File([], ''), // 실제 파일을 사용하지 않는 경우 빈 파일 생성
        originalUrl: currentImage.originalUrl, // originalUrl 유지
        processedUrl: currentImage.url, // 편집된 이미지 URL
        isProcessing: false,
        isSelected: false,
      });
      setIsEditModalOpen(true);
      setEditInitialMenu('crop'); // crop 메뉴로 설정
    } else {
      toast.warning('편집할 이미지를 선택해주세요.');
    }
  };

  // Upscale 4x 핸들러 (기존 핸들러 유지)
  const handleUpscale4x = () => {
    // Upscale 4x 기능 구현 예정
    toast.info('Upscale 4x 버튼이 클릭되었습니다.');
  };

  // Add Logo 핸들러
  const handleAddLogo = () => {
    const currentImage = composeProcessedImages[fullscreenOverlay.currentImageIndex];
    if (currentImage) {
      setImageToEdit({
        id: currentImage.id,
        originalFile: new File([], ''), // 실제 파일을 사용하지 않는 경우 빈 파일 생성
        originalUrl: currentImage.originalUrl, // originalUrl 유지
        processedUrl: currentImage.url, // 편집된 이미지 URL
        isProcessing: false,
        isSelected: false,
      });
      setIsEditModalOpen(true);
      setEditInitialMenu('mask'); // mask 메뉴로 설정
    } else {
      toast.warning('편집할 이미지를 선택해주세요.');
    }
  };

  // 좋아요 핸들러
  const handleLikeImage = async (id: string) => {
    try {
      // 서버에 좋아요 상태 업데이트 요청
      const response = await axiosInstance.put(`/like-image/${id}`);
      if (response.status === 200) {
        // 상태 업데이트 후, 이미지의 liked 상태 반전
        setComposeProcessedImages((prevImages) =>
          prevImages.map((img) =>
            img.id === id ? { ...img, liked: !img.liked } : img
          )
        );
        toast.success('좋아요 상태가 업데이트되었습니다.');
      } else {
        toast.error('좋아요 상태를 업데이트하는 데 실패했습니다.');
      }
    } catch (error) {
      console.error('좋아요 업데이트 실패:', error);
      toast.error('좋아요 상태를 업데이트하는 데 실패했습니다.');
    }
  };

  // // 공유 핸들러
  // const handleShareImage = async (id: string) => {
  //   try {
  //     // 서버에 공유 요청
  //     const response = await axiosInstance.post(`/share-image/${id}`);
  //     if (response.status === 200) {
  //       toast.success('이미지가 성공적으로 공유되었습니다.');
  //     } else {
  //       toast.error('이미지를 공유하는 데 실패했습니다.');
  //     }
  //   } catch (error) {
  //     console.error('이미지 공유 실패:', error);
  //     toast.error('이미지를 공유하는 데 실패했습니다.');
  //   }
  // };

  // 공유 버튼 클릭 -> 모달 열기
  const handleShareImageClick = (image: ComposedImageData) => {
    setShareTargetImage(image);
    console.log('handleShareImageClick : ', image);
    setShareDescription('');    // 초기화
    setShareTags('');
    setOpenShareDialog(true);
  };

  // 모달 닫기
  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
    setShareTargetImage(null);
    setShareDescription('');
    setShareTags('');
  };

  // 실제 서버에 공유 요청 (POST /gallery/share)
  const handleSubmitShare = async () => {
    if (!shareTargetImage) return;

    try {
      // file_path = shareTargetImage.originalUrl (혹은 server accessible URL)
      const payload = {
        // file_path: shareTargetImage.originalUrl, 
        image_url: shareTargetImage.originalUrl, 
        description: shareDescription,
        tags: shareTags,
      };
      const response = await axiosInstance.post('/gallery/share', payload);
      if (response.status === 200) {
        toast.success('Successfully shared the image!');
        // 모달 닫기
        handleCloseShareDialog();
      } else {
        toast.error('Failed to share the image.');
      }
    } catch (error) {
      console.error('Share failed:', error);
      toast.error('Failed to share the image.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Photo Upload 버튼과 슬라이더 바를 같은 줄에 배치 */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 4,
          flexWrap: 'wrap', // 반응형을 위해 래핑 가능하게 설정
          gap: 2, // 요소 간 간격 조절
        }}
      >
        {/* Photo Upload 버튼 항상 렌더링 */}
        <Fade in={true} timeout={500}>
          <Button variant="contained" color="primary" onClick={handleCreateImageClick}>
            Photo Upload
          </Button>
        </Fade>
        
        {/* 슬라이더 바 */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: '300px',
            width: '100%',
          }}
        >
          <Typography variant="body2" sx={{ mr: 1 }}>
            Adjust size:
          </Typography>
          <SliderBar scale={scale} onScaleChange={handleScaleChange} />
        </Box>
      </Box>

      {/* 이미지 뷰어와 로딩 오버레이 */}
      <Box sx={{ position: 'relative', mb: 4 }}>
        <ViewerBox {...getRootProps()}>
          <input {...getInputProps()} />
          <ImageContainer>
            {getViewerBoxImageUrl() ? (
              <Fade in={true} timeout={500}>
                <StyledImage
                  src={getViewerBoxImageUrl()!}
                  alt={`Viewer Image ${selectedImage ? selectedImage.id : 'Default'}`}
                  scale={scale}
                />
              </Fade>
            ) : (
              <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                Please upload or select an image.
              </Typography>
            )}
          </ImageContainer>
          {/* 처리 중인 이미지가 하나라도 있으면 로딩 오버레이 표시 */}
          {(images.some((img) => img.isProcessing) || isLoading) && (
            <LoadingOverlay>
              <CircularProgress />
              <Typography variant="body1" sx={{ mt: 1 }}>
                Processing...
              </Typography>
            </LoadingOverlay>
          )}
        </ViewerBox>
      </Box>

      {/* 편집 결과 이미지 박스 */}
      <EditingResultsBox>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, flexWrap: 'wrap', gap: 2 }}>
          {anySelected ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteSelected}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadSelected}
              >
                Download
              </Button>
            </Box>
          ) : (
            <>
              <Typography variant="h6">Recent</Typography>
              {/* <Tooltip title={(userProfile?.credit ?? 0) <= 0 ? "Insufficient credits to generate an image." : "Generate an image"}>
                <span>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleComposeBackground}
                    disabled={(userProfile?.credit ?? 0) <= 0} // Disable if credit <= 0
                  >
                    Generate
                  </Button>
                </span>
              </Tooltip> */}

              <Tooltip
                title={
                  (userProfile?.credit ?? 0) <= 0
                    ? "Insufficient credits to generate an image."
                    : userProfile?.user_status === 0
                    ? "Account is inactive."
                    : "Generate an image"
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleComposeBackground}
                    disabled={(userProfile?.credit ?? 0) <= 0 ||
                       userProfile?.user_status === 0 ||
                       selectedTab === 'Assets' // Disable when AssetsTab is active
                       } // OR 조건 추가
                  >
                    Generate
                  </Button>
                </span>
              </Tooltip>

            </>
          )}
        </Box>

        {/* ImageList with border */}
        <ImageList 
          cols={isMobile ? 2 : 5}
          gap={8} 
          sx={{ 
            mt: 2, 
            border: '2px dashed #FFEB3B', 
            borderRadius: 1, 
            padding: 2,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: '200px' 
          }}
        >
          {composeProcessedImages.length > 0 ? (
            composeProcessedImages.map((img, index) => (
              <ImageListItem 
                key={img.id} 
                sx={{ 
                  width: itemSize, 
                  height: itemSize, 
                  position: 'relative', 
                  '&:hover': { boxShadow: 3 },
                  cursor: 'zoom-in', // 커서 변경
                }}
                onMouseEnter={() => handleMouseEnter(img.id)}
                onMouseLeave={() => handleMouseLeave(img.id)}
                onClick={() => handleImageListClick(img)} // 이미지 클릭 시 선택
              >
                <Card sx={{ width: '100%', height: '100%' }}>
                  <CardMedia
                    component="img"
                    image={img.url}
                    alt={`Composed Image ${img.id}`}
                    sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                  {img.showOverlay && (
                    <Box
                      className="overlay"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        padding: 1,
                        boxSizing: 'border-box',
                        cursor: 'zoom-in', // 커서 유지
                      }}
                      onClick={() => openFullscreenOverlay(img.id)} // 전체 오버레이 열기
                    >
                      {/* 왼쪽: 체크박스 */}
                      <Checkbox
                        checked={img.isSelected}
                        onChange={() => handleSelectComposedImage(img.id)}
                        sx={{
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          borderRadius: '50%',
                          width: 20,
                          height: 20,
                        }}
                        onClick={(e) => e.stopPropagation()} // 이벤트 전파 중단
                      />
                      
                      {/* 오른쪽: 아이콘 버튼들 */}
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {/* 다운로드 버튼 */}
                        <IconButton
                          aria-label="download"
                          onClick={(e) => {
                            e.stopPropagation(); // 이벤트 전파 중단
                            const link = document.createElement('a');
                            link.href = img.url;
                            link.download = `composed_image_${img.id}.png`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            width: 24,
                            height: 24,
                            padding: 0,
                          }}
                          onMouseDown={(e) => e.stopPropagation()} // 마우스 다운 시 전파 중단
                        >
                          <DownloadIcon fontSize="small" />
                        </IconButton>

                        {/* 좋아요 버튼 */}
                        <IconButton
                          aria-label="like"
                          onClick={(e) => {
                            e.stopPropagation(); // 이벤트 전파 중단
                            handleLikeImage(img.id);
                          }}
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            width: 24,
                            height: 24,
                            padding: 0,
                          }}
                          onMouseDown={(e) => e.stopPropagation()} // 마우스 다운 시 전파 중단
                        >
                          {img.liked ? <FavoriteIcon fontSize="small" color="error" /> : <FavoriteBorderIcon fontSize="small" sx={{ color: '#fff' }} />}
                        </IconButton>

                        {/* 공유 버튼 */}
                        <IconButton
                          aria-label="share"
                          onClick={(e) => {
                            e.stopPropagation(); // 이벤트 전파 중단
                            handleShareImageClick(img)
                          }}
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            width: 24,
                            height: 24,
                            padding: 0,
                          }}
                          onMouseDown={(e) => e.stopPropagation()} // 마우스 다운 시 전파 중단
                        >
                          <ShareIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Card>
              </ImageListItem>
            ))
          ) : (
            <ImageListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px', width: '100%' }}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom>
                  No image yet!!
                </Typography>
                <Button variant="contained" color="primary" onClick={openDropzone}>
                  Create your first image
                </Button>
              </Box>
            </ImageListItem>
          )}
        </ImageList>
      </EditingResultsBox>

      {/* 전체 오버레이 모달 */}
      <Modal
        open={fullscreenOverlay.open}
        onClose={closeFullscreenOverlay}
        aria-labelledby="fullscreen-overlay-title"
        aria-describedby="fullscreen-overlay-description"
      >
        <FullscreenOverlay>
          <OverlayContent isMobile={isMobile}>
            {/* 화살표 버튼 */}
            {!isMobile && (
              <>
                <ArrowButton
                  onClick={handlePrevImage}
                  sx={{ left: 0 }}
                >
                  <ArrowBackIosIcon />
                </ArrowButton>
                <ArrowButton
                  onClick={handleNextImage}
                  sx={{ right: 0 }}
                >
                  <ArrowForwardIosIcon />
                </ArrowButton>
              </>
            )}
            {isMobile && (
              <>
                <ArrowButton
                  onClick={handlePrevImage}
                  sx={{ left: '10px' }}
                >
                  <ArrowBackIosIcon />
                </ArrowButton>
                <ArrowButton
                  onClick={handleNextImage}
                  sx={{ right: '10px' }}
                >
                  <ArrowForwardIosIcon />
                </ArrowButton>
              </>
            )}

            {/* 닫기 버튼 */}
            <CloseButton onClick={closeFullscreenOverlay}>
              <CloseIcon />
            </CloseButton>

            {/* 센터 콘텐츠 */}
            <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
              {/* 좌측: 선택된 이미지 */}
              <Box sx={{ flex: 1, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  src={composeProcessedImages[fullscreenOverlay.currentImageIndex]?.url}
                  alt={`Selected Image ${composeProcessedImages[fullscreenOverlay.currentImageIndex]?.id}`}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </Box>

              {/* 우측: 상단과 하단으로 분리 */}
              <Box sx={{ flex: 1, padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mr: isMobile ? 0 : 16, }}>   {/* 모바일에서는 여백 없음, 데스크탑에서는 여백 추가 */}
                {/* 상단 텍스트 출력 영역 */}
                <Box sx={{ flexBasis: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                  <Typography variant="h6" gutterBottom>
                    {describeText || 'No description provided.'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {dontWantText || 'No additional description.'}
                  </Typography>
                  {selectedCustomImage && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <img
                        src={selectedCustomImage}
                        alt="Reference"
                        style={{ maxWidth: '100%', maxHeight: '150px' }}
                      />
                    </Box>
                  )}
                </Box>

                {/* 대시 라인 */}
                <Divider sx={{ width: '80%', my: 2 }} />

                {/* 하단: 버튼 7개 (2개씩 4줄) */}
                <Box sx={{ flexBasis: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mr: isMobile ? 0 : 16, }}>   {/* 모바일에서는 여백 없음, 데스크탑에서는 여백 추가 */}
                  <ButtonGroupContainer container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit" // color="default"에서 "inherit"로 변경
                        startIcon={<DeleteIcon />}
                        onClick={handleFullscreenDeleteSelected}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit" // color="default"에서 "inherit"로 변경
                        startIcon={<DownloadIcon />}
                        onClick={handleFullscreenDownloadSelected}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit" // color="default"에서 "inherit"로 변경
                        startIcon={<EditIcon />}
                        onClick={handleEdit}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit" // color="default"에서 "inherit"로 변경
                        startIcon={<SwapHorizIcon />} // ReplaceIcon 대신 SwapHorizIcon 사용
                        onClick={handleReplaceProduct}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                      >
                        Replace
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit" // color="default"에서 "inherit"로 변경
                        startIcon={<CropIcon />}
                        onClick={handleResize}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                      >
                        Resize
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit" // color="default"에서 "inherit"로 변경
                        startIcon={<ZoomInIcon />}
                        onClick={handleUpscale4x}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                      >
                        Upscale 4x
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="inherit" // color="default"에서 "inherit"로 변경
                        startIcon={<AddPhotoAlternateIcon />}
                        onClick={handleAddLogo}
                        fullWidth
                        sx={{
                          backgroundColor: '#ffffff',
                          color: 'inherit',
                          border: '1px solid #ccc',
                        }}
                      >
                        Add Logo
                      </Button>
                    </Grid>
                  </ButtonGroupContainer>
                </Box>
              </Box>
            </Box>
          </OverlayContent>
        </FullscreenOverlay>
      </Modal>

      {/* 편집 모달 */}
      {isEditModalOpen && imageToEdit && (
        <ImageEditorComponent
          src={imageToEdit.processedUrl!}
          onSave={handleSaveEditedImage}
          onClose={handleCloseEditModal}
          initialMenu={editInitialMenu} // 설정된 초기 메뉴 전달
        />
      )}

      {/* ========== 공유 다이얼로그 ========== */}
      <Dialog open={openShareDialog} onClose={handleCloseShareDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Share Image</DialogTitle>
        <DialogContent>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline // 다중 행 입력 가능하도록 설정
            rows={4} // TextArea 높이를 결정하는 행 수      
            margin="normal"
            value={shareDescription}
            onChange={(e) => setShareDescription(e.target.value)}
          />
          <TextField
            label="Tags"
            variant="outlined"
            fullWidth
            margin="normal"
            value={shareTags}
            onChange={(e) => setShareTags(e.target.value)}
            helperText="Separate with commas (,) or enter freely"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog}>Cancel</Button>
          <Button onClick={handleSubmitShare} variant="contained" color="primary">
            Share
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default ImageEditorComponentWrapper;
