// src/components/TabsContent/Flux1Schnell.tsx
import React from 'react';
import { Typography, Box } from '@mui/material';

const Flux1Schnell: React.FC = () => {
  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        FLUX.1-schnell
      </Typography>
      <Typography variant="body1">
        FLUX.1-schnell 기능 화면입니다. 여기에 해당 기능에 대한 내용을 추가하세요.
      </Typography>
    </Box>
  );
};

export default Flux1Schnell;
