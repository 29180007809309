// src/contexts/FolderContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { BgFile, Folder, SelectedImage } from '../types'; // 필요한 타입 임포트
import axiosInstance from '../api/axios';
import { toast } from 'react-toastify';

interface FolderContextProps {
  allFolders: Folder[];
  setAllFolders: React.Dispatch<React.SetStateAction<Folder[]>>;
  currentFolderId: string;
  setCurrentFolderId: React.Dispatch<React.SetStateAction<string>>;
  filteredFolders: Folder[];
  setFilteredFolders: React.Dispatch<React.SetStateAction<Folder[]>>;
  filteredFiles: BgFile[];
  setFilteredFiles: React.Dispatch<React.SetStateAction<BgFile[]>>;
  fetchFoldersAndFiles: (folderId: string) => Promise<void>;
  createFolder: (folderName: string) => Promise<void>;
  deleteFile: (fileHash: string) => Promise<void>;
  userEmail: string;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
}

const FolderContext = createContext<FolderContextProps | undefined>(undefined);

export const useFolderContext = (): FolderContextProps => {
  const context = useContext(FolderContext);
  if (!context) {
    throw new Error('useFolderContext must be used within a FolderProvider');
  }
  return context;
};

interface FolderProviderProps {
  children: ReactNode;
}

export const FolderProvider: React.FC<FolderProviderProps> = ({ children }) => {
  const [allFolders, setAllFolders] = useState<Folder[]>([]);
  const [currentFolderId, setCurrentFolderId] = useState<string>('root');
  const [filteredFolders, setFilteredFolders] = useState<Folder[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<BgFile[]>([]);
  const [userEmail, setUserEmail] = useState<string>(''); // 사용자 이메일 상태 추가
  const [loading, setLoading] = useState<boolean>(false);

  // 이메일 주소를 안전한 디렉토리 이름으로 변환하는 함수
  const sanitizeUserEmail = (email: string): string => {
    return email.replace(/[^a-zA-Z0-9]/g, '_');
  };
  

  // 특정 폴더의 하위 폴더와 파일을 가져오는 함수
  const fetchFoldersAndFiles = async (folderId: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/get_folder_contents/${folderId}`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        const { subfolders, files } = response.data;
        console.log('Subfolders:', subfolders);
        console.log('Files:', files); // 파일 데이터 확인
  
        // "Folder_"로 시작하지 않고 "Root"가 아닌 하위 폴더만 필터링
        const filteredSubfolders: Folder[] = subfolders
          .map((folder: any) => ({
            id: folder.id,
            name: folder.name,
            parent_uuid: folder.parent_uuid,
            subfolders: folder.subfolders,
            files: folder.files,
          }))
          .filter(
            (folder: Folder) =>
              !folder.name.startsWith('Folder_') && folder.name !== 'Root'
          );

        // 현재 폴더의 파일만 필터링
        const filteredFiles: BgFile[] = files.map((file: any) => ({
          file_hash: file.file_hash,
          url: file.url,
          filename: file.filename,
          folder_id: folderId,
        }));

        setFilteredFolders(filteredSubfolders);
        setFilteredFiles(filteredFiles);
      } else {
        throw new Error('폴더 내용 가져오기 실패');
      }
    } catch (error) {
      console.error('폴더 내용을 가져오는 중 오류 발생:', error);
      toast.error('폴더 내용을 가져오는 데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 폴더 생성 함수
  const createFolder = async (folderName: string) => {
    if (!folderName.trim()) {
      toast.warning('폴더 이름을 입력해주세요.');
      return;
    }

    try {
      const response = await axiosInstance.post(
        '/create_folder',
        {
          folder_name: folderName.trim(),
          parent_uuid: currentFolderId,
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        toast.success(`폴더 "${folderName.trim()}"이(가) 생성되었습니다.`);
        // 생성된 폴더를 allFolders에 추가
        const newFolder: Folder = {
          id: response.data.uuid, // 서버에서 반환된 UUID
          name: response.data.name || folderName.trim(),
          parent_uuid: currentFolderId,
          subfolders: response.data.subfolders || [],
          files: response.data.files || [],
        };
        setAllFolders((prev) => [...prev, newFolder]);

        // 현재 폴더의 하위 폴더에도 추가
        if (
          !newFolder.name.startsWith('Folder_') &&
          newFolder.name !== 'Root'
        ) {
          setFilteredFolders((prev) => [...prev, newFolder]);
        }
      } else {
        throw new Error('폴더 생성 실패');
      }
    } catch (error) {
      console.error('폴더 생성 중 오류 발생:', error);
      toast.error('폴더 생성에 실패했습니다.');
    }
  };

  // 파일 삭제 함수
  const deleteFile = async (fileHash: string) => {
    const sanitized_email = sanitizeUserEmail(userEmail); // 실제 사용자 이메일로 대체
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      if (!apiBaseUrl) {
        throw new Error('REACT_APP_API_BASE_URL이 설정되지 않았습니다.');
      }
      // const sanitized_email = sanitizeUserEmail('user@example.com'); // 실제 사용자 이메일로 대체
      console.log('### userEmail : ', sanitizeUserEmail, userEmail)
      // const imageUrl = `https://121.78.116.47/processed/${sanitizeUserEmail}/${currentFolderId}/${fileHash}.png`;
      // imageUrl을 환경 변수를 사용하여 동적으로 생성
      const imageUrl = `${apiBaseUrl}/processed/${sanitized_email}/${currentFolderId}/${fileHash}.png`;

      const response = await axiosInstance.post(
        '/delete_gi_file',
        { url: imageUrl },
        { withCredentials: true }
      );

      if (response.status === 200) {
        // 모든 파일 목록에서 제거
        setFilteredFiles((prev) =>
          prev.filter((file) => file.file_hash !== fileHash)
        );
        toast.success('이미지가 삭제되었습니다.');
      } else {
        throw new Error('파일 삭제 실패');
      }
    } catch (error) {
      console.error('이미지 삭제 중 오류 발생:', error);
      toast.error('이미지를 삭제하는 데 실패했습니다.');
    }
  };

  

  return (
    <FolderContext.Provider
      value={{
        allFolders,
        setAllFolders,
        currentFolderId,
        setCurrentFolderId,
        filteredFolders,
        setFilteredFolders,
        filteredFiles,
        setFilteredFiles,
        fetchFoldersAndFiles,
        createFolder,
        deleteFile,
        userEmail, // 이메일 상태 전달
        setUserEmail,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
};
