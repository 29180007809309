// src/firebase.ts
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, User } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBYJZWHe8JZ9HScyuHb3HvwwSbnj_gAtGs",
    authDomain: "pixmon-440408.firebaseapp.com",
    projectId: "pixmon-440408",
    storageBucket: "pixmon-440408.firebasestorage.app",
    messagingSenderId: "147826669586",
    appId: "1:147826669586:web:3876edb1ba9d8ac02640cb",
    measurementId: "G-BTG1PNXNV8"
  };

// Firebase 초기화
const app = initializeApp(firebaseConfig);

// 인증 서비스 초기화
const auth = getAuth(app);

// 구글 인증 제공자 설정
const googleProvider = new GoogleAuthProvider();

// 구글 로그인 함수
const signInWithGoogle = async (): Promise<User | null> => {
    console.log("google login")
    try {
        const result = await signInWithPopup(auth, googleProvider);
        return result.user;
    } catch (error) {
        console.error("Google login error:", error);
        throw error;
    }
};

// 이메일 회원가입 함수
const signUpWithEmail = async (email: string, password: string): Promise<User | null> => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        if (auth.currentUser) {
            await sendEmailVerification(auth.currentUser);
            return auth.currentUser;
        }
        return null;
    } catch (error) {
        console.error("Email sign-up error:", error);
        throw error;
    }
};

// // 이메일 로그인 함수
// const signInWithEmail = async (email: string, password: string): Promise<User | null> => {
//     try {
//         const userCredential = await signInWithEmailAndPassword(auth, email, password);
//         return userCredential.user;
//     } catch (error) {
//         console.error("이메일 로그인 오류:", error);
//         throw error;
//     }
// };

// 이메일 로그인 함수 (emailVerified 상태 확인)
const signInWithEmail = async (email: string, password: string): Promise<User | null> => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // 사용자 데이터 새로고침 (최신 상태 가져오기)
        await user.reload();

        console.log("emailVerified : ", user.emailVerified)

        // 이메일 인증 상태 확인
        if (!user.emailVerified) {
            console.warn("Your email address has not been verified.");
            throw new Error("Your email has not been verified. Please check your email and verify it before logging in again.");
        }

        return user;
    } catch (error) {
        console.error("Email login error:", error);
        throw error;
    }
};

// 비밀번호 재설정 이메일 보내기 함수
const resetPassword = async (email: string): Promise<void> => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        console.error("Password reset error:", error);
        throw error;
    }
};

// 로그아웃 함수
const logout = async (): Promise<void> => {
    try {
        await signOut(auth);
    } catch (error) {
        console.error("Logout error:", error);
        throw error;
    }
};

export {
    auth,
    googleProvider,
    signInWithGoogle,
    signUpWithEmail,
    signInWithEmail,
    resetPassword,
    logout,
};


// // src/firebase.ts
// import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

// // Firebase 초기화
// const app = initializeApp(firebaseConfig);

// // 인증 서비스 초기화
// const auth = getAuth(app);

// // 구글 인증 제공자 설정
// const googleProvider = new GoogleAuthProvider();

// export { auth, googleProvider };