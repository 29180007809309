// src/pages/CopyrightPage.tsx
import React from "react";
import { Box, Typography, Link, List, ListItem, ListItemText } from "@mui/material";
import Navbar from "../components/Navbar";

const CopyrightPage: React.FC = () => {
  return (
    <Box>
      <Navbar />
      <Box sx={{ textAlign: "left", padding: "100px 20px", maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h2" sx={{ fontSize: "48px", fontWeight: "bold", color: "#333" }}>
          Copyright Notice
        </Typography>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          1. Copyright Policy
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          1.1 Copyright of Generated Images
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Copyright of images generated through this service belongs to the member who created them. Members can use
          generated images for commercial purposes.
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          1.2 Copyright of Service Content
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Copyright of all content provided by the service, including software, images, and text, belongs to the
          company. Members may not reproduce, distribute, or transmit service content without authorization.
        </Typography>

        <Typography variant="h4" sx={{ fontSize: "24px", fontWeight: "bold", marginTop: "40px" }}>
          2. Image Usage Rights
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          2.1 Commercial Use
        </Typography>
        <Typography sx={{ fontSize: "18px", margin: "10px 0", color: "#555" }}>
          Members can use images generated through the service for commercial purposes. However, generated images must
          not infringe on third-party rights.
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px" }}>
          2.2 Restrictions
        </Typography>
        <List sx={{ color: "#555", marginLeft: "20px" }}>
          <ListItem>
            <ListItemText primary="Prohibited creation and use of images for illegal purposes" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Prohibited creation of images that defame others or violate privacy" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Prohibited creation of adult or violent content" />
          </ListItem>
        </List>
      </Box>

      {/* Footer */}
      <Box
        className="footer"
        sx={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#333",
          color: "#fff",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          PIXMON
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap" }}>
          <Link href="/about" color="inherit" underline="none">
            About Us
          </Link>
          <Link href="/contact" color="inherit" underline="none">
            Contact
          </Link>
          <Link href="/terms" color="inherit" underline="none">
            Terms of Service
          </Link>
          <Link href="/privacy" color="inherit" underline="none">
            Privacy Policy
          </Link>
          <Link href="/copyright" color="inherit" underline="none">
            Copyright
          </Link>
          <Link href="/support" color="inherit" underline="none">
            Support
          </Link>
        </Box>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          &copy; 2025 PIXMON. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default CopyrightPage;
