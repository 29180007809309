// src/contexts/UserContext.tsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth } from './AuthContext';
import axiosInstance from '../api/axios';
import { toast } from 'react-toastify';

interface UserProfile {
    uid: string;
    email: string;
    display_name?: string;
    photo_url?: string;
    email_verified: boolean;
    nickname?: string;
    user_level: number;
    subscription_active: boolean;
    subscription_expires_at?: string;
    credit: number;
    user_status: number;
    current_plan: number;
    plan_status: number;
    theme: number;
    // 추가 필드...
}

interface UserContextProps {
    userProfile: UserProfile | null;
    loading: boolean;
    error: string | null;
    updateCredit: (amount: number) => Promise<void>;
}

const UserContext = createContext<UserContextProps>({
    userProfile: null,
    loading: true,
    error: null,
    updateCredit: async () => {},
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const { currentUser } = useAuth();
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (currentUser) {
                try {
                    const idToken = await currentUser.getIdToken();
                    const response = await axiosInstance.get<UserProfile>('/me', {
                        headers: {
                            Authorization: `Bearer ${idToken}`
                        }
                    });
                    setUserProfile(response.data);
                } catch (err: any) {
                    console.error("사용자 프로필 가져오기 오류:", err);
                    setError("사용자 정보를 가져오는 데 실패했습니다.");
                    setUserProfile(null);
                } finally {
                    setLoading(false);
                }
            } else {
                setUserProfile(null);
                setLoading(false);
            }
        };
        fetchUserProfile();
    }, [currentUser]);


    
    const updateCredit = async (amount: number) => {
        if (!currentUser) {
            toast.error("로그인이 필요합니다.");
            return;
        }
        try {
            const idToken = await currentUser.getIdToken();
            const response = await axiosInstance.post('/deduct-credit', 
                { amount }, // JSON 형식으로 데이터 전송
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log("Credit updated:", response.data.credit); // 디버깅 로그
            setUserProfile(prev => prev ? { ...prev, credit: response.data.credit } : prev);
            toast.success(`Credit has been deducted by ${amount}.`);
        } catch (err: any) {
            console.error("Credit 업데이트 오류:", err);
            if (err.response && err.response.data && err.response.data.detail) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Credit 업데이트에 실패했습니다.");
            }
        }
    };
    

    return (
        <UserContext.Provider value={{ userProfile, loading, error, updateCredit }}>
            {children}
        </UserContext.Provider>
    );
};
